import { Injectable } from '@angular/core';
import { AlertService } from '@app/services/alert.service';
import { API } from '@app/services/api.service';
import { BaseService } from '@app/services/base.service';

@Injectable()
export class CardService extends BaseService {
  constructor(api: API, alertService: AlertService) {
    super(api, alertService);
    this.route = 'cards';
  }

  getByScanCode(scan_code) {
    return this.api.get(this.route + '/scan/' + scan_code);
  }
}
