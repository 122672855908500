import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/services/app.service';
declare var $: any;

@Component({
  selector: 'app-self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.scss']
})
export class SelfServiceComponent implements OnInit {
  constructor(public router: Router, public appService: AppService) {}

  ngOnInit() {
    if (this.router.url === '/self-service') {
      this.router.navigate(['/self-service', { outlets: { self_service: 'start' } }]);
    }

    $(window).on('keydown', e => {
      // Key: H
      if (e.keyCode === 72) {
        this.appService.showMenu();
      }
    });

    $(window).on('keyup', e => {
      // Key: H
      if (e.keyCode === 72) {
        this.appService.hideMenu();
      }
    });
  }
}
