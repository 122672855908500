import { Component, OnInit, OnDestroy } from '@angular/core';
import { BroadcastService } from '@app/services/broadcast.service';
import { AuthService } from '@app/services/auth.service';
import { UserService } from '@app/services/user.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { SettingsService } from '@app/services/settings.service';
declare var $: any;
const PROFILE_IMAGE = '/assets/images/default_profile.jpg';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  public environment = environment;
  public broadcasts = [];
  public broadcast;
  public settings;
  public user;
  public validation = {
    profileImage: true,
    fullName: true,
    dob: true
  };

  constructor(
    public broadcastService: BroadcastService,
    public authService: AuthService,
    public userService: UserService,
    public settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.reset();
    this.getUser();
    this.getSettings();
    this.getBroadcasts();

    $('#broadcast_message').on('keydown', e => {
      if (e.which === 13) {
        this.addBroadcast();
      }
    });
  }

  ngOnDestroy() {
    const _id = JSON.parse(localStorage.getItem('user'))._id;
    this.userService.getById(_id).subscribe(user => {
      if (localStorage.getItem('user')) {
        this.setUser(user);
        this.update();
      }
    });
  }

  reset() {
    this.broadcast = {
      type: 'info'
    };
  }

  getSettings() {
    this.settingsService.get().subscribe(settings => (this.settings = settings));
  }

  saveSettings() {
    this.settingsService.save(this.settings).subscribe();
  }

  getBroadcasts() {
    this.broadcastService.get().subscribe(broadcasts => (this.broadcasts = broadcasts));
  }

  getUser() {
    const _id = JSON.parse(localStorage.getItem('user'))._id;
    this.userService.getById(_id).subscribe(user => this.setUser(user));
  }

  setUser(user) {
    this.user = user;
    this.user.password = '';
    this.user.dob = moment(this.user.dob).format('YYYY-MM-DD');

    $('.col-md-6 .profile-image-container').css(
      'background-image',
      'url(' + environment.IP_ADDRESS + '/api/upload/' + this.user.profileImage + '.jpg)'
    );
  }

  showProfileImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (event2: any) => {
        this.user.profileImage = event2.target.result;
        $('.col-md-6 .profile-image-container').css('background-image', 'url(' + this.user.profileImage + ')');
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  update() {
    if (this.user.night_mode_setting !== 'auto') {
      localStorage.setItem('night_mode_setting', this.user.night_mode_setting);
    } else {
      localStorage.removeItem('night_mode_setting');
    }

    localStorage.setItem('language', this.user.language);
  }

  saveUser() {
    this.validation.profileImage = this.user.profileImage !== PROFILE_IMAGE;
    this.validation.fullName = !!this.user.fullName;
    this.validation.dob = !!this.user.dob;

    if (this.validation.profileImage && this.validation.fullName && this.validation.dob) {
      this.userService.update(this.user).subscribe(() => this.getUser());
    }
  }

  addBroadcast() {
    if (!this.broadcast.message) {
      return;
    }

    this.broadcastService.create(this.broadcast).subscribe(() => {
      this.reset();
      this.getBroadcasts();
    });
  }

  deleteBroadcast(broadcast) {
    this.broadcastService.delete(broadcast).subscribe(() => {
      for (let i = 0; i < this.broadcasts.length; i++) {
        const e = this.broadcasts[i];
        if (broadcast._id === e._id) {
          this.broadcasts.splice(i, 1);
          return;
        }
      }
    });
  }
}
