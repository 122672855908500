import { Injectable } from '@angular/core';
import { AlertService } from '@app/services/alert.service';
import { LoadingService } from '@app/services/loading.service';
import { API } from '@app/services/api.service';
import { BaseService } from '@app/services/base.service';

@Injectable()
export class MealsService extends BaseService {
  constructor(api: API, alertService: AlertService) {
    super(api, alertService);
    this.route = 'meals';
  }
}
