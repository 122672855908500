import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AlertService } from '@app/services/alert.service';
import { AuthService } from '@app/services/auth.service';
import { LoadingService } from '@app/services/loading.service';
import { Router } from '@angular/router';

@Injectable()
export class API {
  public url = environment.IP_ADDRESS + '/api/';

  constructor(public http: HttpClient, public loadingService: LoadingService, public alertService: AlertService, public router: Router) {}

  request(type: string, endpoint: string, params?: any, body?: any, reqOpts?: any, options?: any) {
    return new Observable(observer => {
      if (!reqOpts) {
        reqOpts = {
          params: new HttpParams()
        };
      }

      if (params) {
        reqOpts.params = new HttpParams();

        for (const k in params) {
          if (params.hasOwnProperty(k)) {
            const param = params[k];
            reqOpts.params = reqOpts.params.set(k, param);
          }
        }
      }

      if (localStorage.getItem('token')) {
        reqOpts.headers = {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        };
      } else {
        reqOpts.headers = {};
      }

      let query;

      switch (type) {
        case 'get':
          query = this.http.get(this.url + endpoint, reqOpts);
          break;
        case 'post':
          query = this.http.post(this.url + endpoint, body, reqOpts);
          break;
        case 'put':
          query = this.http.put(this.url + endpoint, body, reqOpts);
          break;
        case 'delete':
          query = this.http.delete(this.url + endpoint, reqOpts);
          break;
        case 'patch':
          query = this.http.patch(this.url + endpoint, body, reqOpts);
          break;
        default:
          query = this.http.get(this.url + endpoint, reqOpts);
          break;
      }

      if (!options || !options.disableLoader) {
        this.loadingService.showLoader(query);
      }

      query.subscribe(
        res => {
          if (!options || !options.disableLoader) {
            this.loadingService.hideLoader(query);
          }

          observer.next(res);
          observer.complete();
        },
        err => {
          console.log(err);
          if (!options || !options.disableLoader) {
            this.loadingService.hideLoader(query);
          }

          if (err && (!options || !options.ignoreErrors)) {
            if (err.status === 401 && (err.error.message.includes('SESSION') || err.error.message === 'No authorization token was found')) {
              if (localStorage.getItem('token')) {
                localStorage.removeItem('session');
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('permissions');
                localStorage.removeItem('night_mode_setting');
                localStorage.removeItem('language');

                this.alertService.removeAlerts();
                this.alertService.showWarning('INVALID_SESSION');
              }

              this.router.navigate(['/login']);
            } else {
              this.alertService.handleError(err);
            }
          }

          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  get(endpoint: string, params?: any, reqOpts?: any, options?: any): Observable<any> {
    return this.request('get', endpoint, params, null, reqOpts, options);
  }

  post(endpoint: string, body: any, reqOpts?: any, options?: any): Observable<any> {
    return this.request('post', endpoint, null, body, reqOpts, options);
  }

  put(endpoint: string, body: any, reqOpts?: any, options?: any): Observable<any> {
    return this.request('put', endpoint, null, body, reqOpts, options);
  }

  delete(endpoint: string, reqOpts?: any, options?: any): Observable<any> {
    return this.request('delete', endpoint, null, null, reqOpts, options);
  }

  patch(endpoint: string, body: any, reqOpts?: any, options?: any): Observable<any> {
    return this.request('patch', endpoint, null, body, reqOpts, options);
  }
}
