import { Location, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertComponent } from '@app/alert/alert.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { LoginComponent } from '@app/auth/login/login.component';
import { LogoutComponent } from '@app/auth/logout/logout.component';
import { LoadingBarComponent } from '@app/loading/loading-bar/loading-bar.component';
import { NavbarComponent } from '@app/navbar/navbar.component';
import { CardsCreateComponent } from '@app/sections/administration/cards/cards-create/cards-create.component';
import { CardsEditComponent } from '@app/sections/administration/cards/cards-edit/cards-edit.component';
import { CardsComponent } from '@app/sections/administration/cards/cards.component';
import { GroupsCreateComponent } from '@app/sections/administration/groups/groups-create/groups-create.component';
import { GroupsEditComponent } from '@app/sections/administration/groups/groups-edit/groups-edit.component';
import { GroupsComponent } from '@app/sections/administration/groups/groups.component';
import { PermissionsComponent } from '@app/sections/administration/permissions/permissions.component';
import { UsersCreateComponent } from '@app/sections/administration/users/users-create/users-create.component';
import { UsersEditComponent } from '@app/sections/administration/users/users-edit/users-edit.component';
import { UsersComponent } from '@app/sections/administration/users/users.component';
import { CategoriesCreateComponent } from '@app/sections/bar/categories/categories-create/categories-create.component';
import { CategoriesEditComponent } from '@app/sections/bar/categories/categories-edit/categories-edit.component';
import { CategoriesComponent } from '@app/sections/bar/categories/categories.component';
import { ProductsCreateComponent } from '@app/sections/bar/products/products-create/products-create.component';
import { ProductsEditComponent } from '@app/sections/bar/products/products-edit/products-edit.component';
import { ProductsComponent } from '@app/sections/bar/products/products.component';
import { ChooseToppingsComponent } from '@app/sections/bar/sales/choose-toppings/choose-toppings.component';
import { SalesComponent } from '@app/sections/bar/sales/sales.component';
import { SnackbarComponent } from '@app/sections/bar/snackbar/snackbar.component';
import { MutationsComponent } from '@app/sections/finance/mutations/mutations.component';
import { TransactionsExportComponent } from '@app/sections/finance/transactions-export/transactions-export.component';
import { TransactionsComponent } from '@app/sections/finance/transactions/transactions.component';
import { FirstAidComponent } from '@app/sections/first-aid/first-aid.component';
import { HomeComponent } from '@app/sections/home/home.component';
import { GoodsExportCreateComponent } from '@app/sections/kitchen/goods-export/goods-export-create/goods-export-create.component';
import { GoodsExportComponent } from '@app/sections/kitchen/goods-export/goods-export.component';
import { GoodsCreateComponent } from '@app/sections/kitchen/goods/goods-create/goods-create.component';
import { GoodsComponent } from '@app/sections/kitchen/goods/goods.component';
import { MealsComponent } from '@app/sections/kitchen/meals/meals.component';
import { CheckInCheckOutComponent } from '@app/sections/security/check-in-check-out/check-in-check-out.component';
import { PresencesComponent } from '@app/sections/security/presences/presences.component';
import { SettingsComponent } from '@app/sections/settings/settings.component';
import { SelfServiceComponent } from '@app/self-service/self-service.component';
import { SsConfirmComponent } from '@app/self-service/ss-confirm/ss-confirm.component';
import { SsFinishComponent } from '@app/self-service/ss-finish/ss-finish.component';
import { SsOrderComponent } from '@app/self-service/ss-order/ss-order.component';
import { SsStartComponent } from '@app/self-service/ss-start/ss-start.component';
import { AlertService } from '@app/services/alert.service';
import { API } from '@app/services/api.service';
import { AppService } from '@app/services/app.service';
import { AuthService } from '@app/services/auth.service';
import { BroadcastService } from '@app/services/broadcast.service';
import { CardService } from '@app/services/card.service';
import { CategoryService } from '@app/services/category.service';
import { DeleteService } from '@app/services/delete.service';
import { GroupService } from '@app/services/group.service';
import { LoadingService } from '@app/services/loading.service';
import { MealsService } from '@app/services/meals.service';
import { NightModeService } from '@app/services/night-mode.service';
import { OrderService } from '@app/services/order.service';
import { PresencesService } from '@app/services/presences.service';
import { ProductService } from '@app/services/product.service';
import { RegistrationService } from '@app/services/registration.service';
import { SalesService } from '@app/services/sales.service';
import { ScanService } from '@app/services/scan.service';
import { SettingsService } from '@app/services/settings.service';
import { TransactionService } from '@app/services/transaction.service';
import { UserService } from '@app/services/user.service';
import { AgePipe } from '@app/utils/age.pipe';
import { ContextMenuComponent } from '@app/utils/context-menu/context-menu.component';
import { DeleteModalComponent } from '@app/utils/delete-modal/delete-modal.component';
import { SafePipe } from '@app/utils/safe.pipe';
import { SelectSearchComponent } from '@app/utils/select-search/select-search.component';
import { TimePastPipe } from '@app/utils/time-past.pipe';
import { TransactionsListComponent } from '@app/utils/transactions-list/transactions-list.component';
import { UserCardComponent } from '@app/utils/user-card/user-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserIdleModule } from 'angular-user-idle';
import { ContextMenuModule } from 'ngx-contextmenu';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NavSideMenuComponent } from './components/nav-side-menu/nav-side-menu.component';

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function getLanguage(translateService: TranslateService, titleService: Title) {
  const language = localStorage.getItem('language') || 'nl-NL';
  translateService.use(language.split('-')[0]);

  if (titleService) {
    translateService.get('TITLE').subscribe(res => {
      titleService.setTitle(res);
    });
  }

  return language;
}

@NgModule({
  declarations: [
    AgePipe,
    AlertComponent,
    AppComponent,
    CardsComponent,
    CardsCreateComponent,
    CardsEditComponent,
    CategoriesComponent,
    CategoriesCreateComponent,
    CategoriesEditComponent,
    CheckInCheckOutComponent,
    ChooseToppingsComponent,
    ContextMenuComponent,
    DeleteModalComponent,
    GoodsComponent,
    GoodsCreateComponent,
    GoodsExportComponent,
    GoodsExportCreateComponent,
    GroupsComponent,
    GroupsCreateComponent,
    GroupsEditComponent,
    HomeComponent,
    LoadingBarComponent,
    LoginComponent,
    LogoutComponent,
    MealsComponent,
    MutationsComponent,
    NavbarComponent,
    PermissionsComponent,
    PresencesComponent,
    ProductsComponent,
    ProductsCreateComponent,
    ProductsEditComponent,
    SafePipe,
    SalesComponent,
    SelectSearchComponent,
    SettingsComponent,
    SnackbarComponent,
    TimePastPipe,
    TransactionsComponent,
    TransactionsListComponent,
    UserCardComponent,
    UsersComponent,
    UsersCreateComponent,
    UsersEditComponent,
    SelfServiceComponent,
    TransactionsExportComponent,
    SsStartComponent,
    SsOrderComponent,
    SsConfirmComponent,
    SsFinishComponent,
    FirstAidComponent,
    NavSideMenuComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ContextMenuModule.forRoot({ autoFocus: true, useBootstrap4: true }),
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] } }),
    UserIdleModule.forRoot({ idle: 300, timeout: 30, ping: 1 }),
    FontAwesomeModule,
    BrowserAnimationsModule
  ],
  providers: [
    AlertService,
    API,
    AuthService,
    BroadcastService,
    CardService,
    CategoryService,
    DeleteService,
    GroupService,
    LoadingService,
    Location,
    MealsService,
    NightModeService,
    OrderService,
    PresencesService,
    ProductService,
    RegistrationService,
    SalesService,
    ScanService,
    SettingsService,
    AppService,
    TransactionService,
    TranslateService,
    UserService,
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: getLanguage
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private language;

  constructor(public translateService: TranslateService, public titleService: Title) {
    library.add(fas, far, fal);
    setInterval(() => this.updateLanguage(), 1000);
  }

  updateLanguage() {
    const newLanguage = getLanguage(this.translateService, this.titleService);
    if (this.language !== newLanguage) {
      this.language = newLanguage;
      console.log('Using language: ' + newLanguage);
    }
  }
}
