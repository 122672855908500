import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoadingService } from '@app/services/loading.service';
import { ScanService } from '@app/services/scan.service';
import { AuthService } from '@app/services/auth.service';
import { environment } from 'environments/environment';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public scanObserver: Subscription;
  public username: String;
  public password: String;
  public remember_me: Boolean;
  public useNFC: Boolean;
  public env = environment;

  constructor(public authService: AuthService, public router: Router, public loading: LoadingService, public scanService: ScanService) {}

  ngOnInit() {
    if (localStorage.getItem('token') !== null) {
      this.router.navigate(['/home']);

      // setTimeout(() => {
      //   if ($('.navbar-button').length > 2) {
      //     $('.navbar-button:nth-child(2)').click();
      //   }
      // }, 50);

      if (localStorage.getItem('default_page') !== null) {
        this.router.navigate(JSON.parse(localStorage.getItem('default_page')));
      }
    }

    if (localStorage.getItem('use_nfc')) {
      this.useNFC = true;
    }

    this.scanObserver = this.scanService.getObserver().subscribe(scan_code => {
      if (!this.useNFC) {
        return;
      }
      this.authService.loginWithScan(scan_code, this.remember_me);
    });
  }

  ngOnDestroy() {
    this.scanObserver.unsubscribe();
  }

  toggleNFC() {
    this.useNFC = !this.useNFC;

    if (this.useNFC) {
      localStorage.setItem('use_nfc', 'true');
    } else {
      localStorage.removeItem('use_nfc');
    }
  }

  pressedLogin() {
    this.authService.login(this.username, this.password, this.remember_me);
  }
}
