import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { API } from '@app/services/api.service';
import { AlertService } from '@app/services/alert.service';

@Injectable()
export class RegistrationService extends BaseService {
  constructor(api: API, alertService: AlertService) {
    super(api, alertService);
    this.route = 'registrations';
  }

  getByUser(id) {
    return this.api.get(this.route + '/user/' + id);
  }

  status() {
    return this.api.get(this.route + '/status');
  }
}
