import { Component, OnInit } from '@angular/core';
import { BroadcastService } from '@app/services/broadcast.service';
import { ProductService } from '@app/services/product.service';
import { AuthService } from '@app/services/auth.service';
import { TransactionService } from '@app/services/transaction.service';
import { environment } from 'environments/environment';
import { RegistrationService } from '@app/services/registration.service';
import { MealsService } from '@app/services/meals.service';
import * as moment from 'moment';
import { AppService } from '@app/services/app.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public env = environment;
  public broadcasts = [];
  public new_broadcasts = [];
  public products = [];
  public products_danger = [];
  public products_warning = [];
  public transactions = [];
  public presences;
  public meal;

  constructor(
    public broadcastService: BroadcastService,
    public productService: ProductService,
    public authService: AuthService,
    public transactionService: TransactionService,
    public registrationService: RegistrationService,
    public mealService: MealsService,
    public appService: AppService
  ) {}

  ngOnInit() {
    this.getBroadcasts();

    if (this.authService.hasPermission('products:*')) {
      this.getProducts();
    }

    if (this.authService.hasPermission('security_checkinout')) {
      this.getRegistrationStatus();
    }

    if (this.authService.hasPermission('kitchen_meals')) {
      this.getMealInfo();
    }

    if (this.authService.hasPermission('finance_transactions')) {
      this.getTransactions();
    }
  }

  getBroadcasts() {
    this.broadcastService.get().subscribe(broadcasts => {
      this.broadcasts = broadcasts;
      this.broadcasts.forEach(broadcast => {
        if (!broadcast.read_by.includes(this.authService.getUser()._id)) {
          this.new_broadcasts.push(broadcast);
        }
      });

      if (this.new_broadcasts.length > 0) {
        $('#modal-broadcasts').modal('show');
      }
    });
  }

  confirmBroadcasts() {
    this.new_broadcasts.forEach(broadcast => {
      this.broadcastService.read(broadcast._id).subscribe();
    });

    $('#modal-broadcasts').modal('hide');
  }

  getProducts() {
    this.productService.get({ limit: Number.MAX_SAFE_INTEGER }).subscribe(products => {
      this.products = products;
      this.getProductsDanger();
      this.getProductsWarning();
    });
  }

  getProductsDanger() {
    const products = [];

    this.products.forEach(product => {
      if (product.minStock > 0 && product.minStock > product.stock) {
        products.push(product);
      }
    });

    this.products_danger = products;
  }

  getProductsWarning() {
    const products = [];

    this.products.forEach(product => {
      if (product.minStock === 0 || this.products_danger.includes(product)) {
        return;
      }

      const minStock_lowerbound = product.minStock + product.minStock * 0.2;

      if (minStock_lowerbound > product.stock) {
        products.push(product);
      }
    });

    this.products_warning = products;
  }

  getTransactions() {
    this.transactionService.get({ limit: 5 }).subscribe(transactions => (this.transactions = transactions));
  }

  getRegistrationStatus() {
    this.registrationService.status().subscribe(total_present => (this.presences = total_present));
  }

  getMealInfo() {
    this.mealService
      .get({
        query: {
          date: {
            $gt: new Date(
              moment()
                .subtract(4, 'hours')
                .toString()
            )
          }
        },
        limit: 1
      })
      .subscribe(meals => {
        if (meals.length < 1) {
          return;
        }

        this.mealService.getById(meals[0]._id).subscribe(meal => (this.meal = meal));
      });
  }
}
