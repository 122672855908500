import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ss-finish',
  templateUrl: './ss-finish.component.html',
  styleUrls: ['./ss-finish.component.scss']
})
export class SsFinishComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit() {
    setTimeout(() => {
      this.router.navigate(['/self-service', { outlets: { self_service: 'start' } }]);
    }, 1000 * 5);
  }
}
