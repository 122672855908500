import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { AlertService } from '@app/services/alert.service';

@Injectable()
export class AppService {
  public menuShown: Boolean;
  public help_cooldown;

  constructor(public http: HttpClient, public alertService: AlertService) {}

  helpHasCooldown() {
    return this.help_cooldown && moment().isBefore(this.help_cooldown);
  }

  requestHelp() {
    if (this.helpHasCooldown()) {
      this.alertService.showWarning('HELP.COOLDOWN');
      return;
    }

    this.help_cooldown = moment().add('3', 'minutes');

    const keys = [
      'ujnftxjws4s3vfst7h2y84612sh6dk', // Stef
      'upueuo6wehq39r9j21te2kyhc9b8uz', // Dylan
      'uztxt1udq9ytjz9krnszw7rp8jifmx' // Jorn
    ];
    let first = true;

    keys.forEach(key => {
      const query = this.http.post('https://api.pushover.net/1/messages.json', {
        token: 'a38997uepj86axp2vn5os4u7okefwe',
        user: key,
        title: 'KJO-POS: Help Requested',
        message: `${JSON.parse(localStorage.getItem('user')).fullName} requested your help at POS terminal ${localStorage.getItem(
          'terminal'
        ) || 'unknown'}`,
        priority: 2,
        expire: 300,
        retry: 30,
        sound: 'echo'
      });

      if (first) {
        query.subscribe(
          () => this.alertService.showSuccess('HELP.SUCCESS_REQUESTED', 30 * 1000),
          () => this.alertService.showError('HELP.FAILED_REQUESTED', 30 * 1000)
        );

        first = false;
      } else {
        query.subscribe();
      }
    });
  }

  showMenu() {
    this.menuShown = true;
  }

  hideMenu() {
    this.menuShown = false;
  }

  isMenuShown() {
    return this.menuShown;
  }
}
