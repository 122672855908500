import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AlertService } from '@app/services/alert.service';
import { ScanService } from '@app/services/scan.service';
import { CardService } from '@app/services/card.service';
import { UserService } from '@app/services/user.service';
import { TransactionService } from '@app/services/transaction.service';
declare var $: any;

@Component({
  selector: 'app-mutations',
  templateUrl: './mutations.component.html',
  styleUrls: ['./mutations.component.scss']
})
export class MutationsComponent implements OnInit, OnDestroy {
  public transactions: any;
  public env = environment;
  public searchTime: any;
  public amount: number = 0;
  public query = '';
  public scanObserver;
  public user;
  public balance = {
    debit: 0,
    credit: 0,
    credit_ceil: 0,
    compensation: 0
  };

  constructor(
    public cardService: CardService,
    public alertService: AlertService,
    public userService: UserService,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public transactionService: TransactionService,
    public scanService: ScanService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.reset();
      this.getUser();
    });

    this.scanObserver = this.scanService.getObserver().subscribe(scan_code => {
      this.query = scan_code;
      this.search();
    });

    $('input').click(function(e) {
      $(this).select();
    });

    $('input[type=text]').keydown(function(e) {
      if (e.which === 13) {
        $(this).select();
      }
    });
  }

  ngOnDestroy() {
    this.scanService.setHandleFocus(false);
    this.scanObserver.unsubscribe();
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.getUser();
    }, 300);
  }

  reset() {
    this.balance = {
      debit: 0,
      credit: 0,
      credit_ceil: 0,
      compensation: 0
    };

    this.transactions = [];
  }

  getUser() {
    if (!this.query) {
      return;
    }

    this.cardService.getByScanCode(this.query).subscribe(card => {
      this.setUser(card.user);
    });
  }

  setUser(user) {
    this.user = user;

    this.user.balance.debit = user.balance.debit;
    this.user.balance.credit = user.balance.credit;
    this.balance.credit_ceil = user.balance.credit_ceil;
    this.balance.compensation = user.balance.compensation;

    this.transactionService.getByUser(user._id).subscribe(transactions => (this.transactions = transactions));
  }

  depositDebit() {
    const transaction = {
      user: this.user,
      amount: this.balance.debit,
      transaction_type: 'deposit',
      balance_type: 'debit'
    };

    this.transactionService.create(transaction).subscribe(e => {
      this.balance.debit = 0;
      this.setUser(e.user);
    });
  }

  withdrawDebit() {
    const transaction = {
      user: this.user,
      amount: this.balance.debit,
      transaction_type: 'withdraw',
      balance_type: 'debit'
    };

    this.transactionService.create(transaction).subscribe(e => {
      this.balance.debit = 0;
      this.setUser(e.user);
    });
  }

  applyCredit() {
    const transaction = {
      user: this.user,
      amount: this.balance.credit,
      transaction_type: 'deposit',
      balance_type: 'credit'
    };

    this.transactionService.create(transaction).subscribe(e => {
      this.balance.credit = 0;
      this.setUser(e.user);
    });
  }

  redeemCredit() {
    const transaction = {
      user: this.user,
      amount: this.balance.credit,
      transaction_type: 'withdraw',
      balance_type: 'credit'
    };

    this.transactionService.create(transaction).subscribe(e => {
      this.balance.credit = 0;
      this.setUser(e.user);
    });
  }

  saveCreditCeil() {
    const transaction = {
      user: this.user,
      amount: this.balance.credit_ceil,
      transaction_type: 'set',
      balance_type: 'credit_ceil'
    };

    this.user.balance.credit_ceil = this.balance.credit_ceil;

    this.transactionService.create(transaction).subscribe(e => this.setUser(this.user));
  }

  saveCompensation() {
    const transaction = {
      user: this.user,
      amount: this.balance.compensation,
      transaction_type: 'set',
      balance_type: 'compensation'
    };

    this.user.balance.compensation = this.balance.compensation;

    this.transactionService.create(transaction).subscribe(e => this.setUser(this.user));
  }
}
