import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@app/auth/login/login.component';
import { LogoutComponent } from '@app/auth/logout/logout.component';
import { CardsCreateComponent } from '@app/sections/administration/cards/cards-create/cards-create.component';
import { CardsEditComponent } from '@app/sections/administration/cards/cards-edit/cards-edit.component';
import { CardsComponent } from '@app/sections/administration/cards/cards.component';
import { GroupsCreateComponent } from '@app/sections/administration/groups/groups-create/groups-create.component';
import { GroupsEditComponent } from '@app/sections/administration/groups/groups-edit/groups-edit.component';
import { GroupsComponent } from '@app/sections/administration/groups/groups.component';
import { PermissionsComponent } from '@app/sections/administration/permissions/permissions.component';
import { UsersCreateComponent } from '@app/sections/administration/users/users-create/users-create.component';
import { UsersEditComponent } from '@app/sections/administration/users/users-edit/users-edit.component';
import { UsersComponent } from '@app/sections/administration/users/users.component';
import { CategoriesCreateComponent } from '@app/sections/bar/categories/categories-create/categories-create.component';
import { CategoriesEditComponent } from '@app/sections/bar/categories/categories-edit/categories-edit.component';
import { CategoriesComponent } from '@app/sections/bar/categories/categories.component';
import { ProductsCreateComponent } from '@app/sections/bar/products/products-create/products-create.component';
import { ProductsEditComponent } from '@app/sections/bar/products/products-edit/products-edit.component';
import { ProductsComponent } from '@app/sections/bar/products/products.component';
import { ChooseToppingsComponent } from '@app/sections/bar/sales/choose-toppings/choose-toppings.component';
import { SalesComponent } from '@app/sections/bar/sales/sales.component';
import { SnackbarComponent } from '@app/sections/bar/snackbar/snackbar.component';
import { MutationsComponent } from '@app/sections/finance/mutations/mutations.component';
import { TransactionsExportComponent } from '@app/sections/finance/transactions-export/transactions-export.component';
import { TransactionsComponent } from '@app/sections/finance/transactions/transactions.component';
import { FirstAidComponent } from '@app/sections/first-aid/first-aid.component';
import { HomeComponent } from '@app/sections/home/home.component';
import { GoodsExportCreateComponent } from '@app/sections/kitchen/goods-export/goods-export-create/goods-export-create.component';
import { GoodsExportComponent } from '@app/sections/kitchen/goods-export/goods-export.component';
import { GoodsCreateComponent } from '@app/sections/kitchen/goods/goods-create/goods-create.component';
import { GoodsComponent } from '@app/sections/kitchen/goods/goods.component';
import { MealsComponent } from '@app/sections/kitchen/meals/meals.component';
import { CheckInCheckOutComponent } from '@app/sections/security/check-in-check-out/check-in-check-out.component';
import { PresencesComponent } from '@app/sections/security/presences/presences.component';
import { SettingsComponent } from '@app/sections/settings/settings.component';
import { SelfServiceComponent } from '@app/self-service/self-service.component';
import { SsConfirmComponent } from '@app/self-service/ss-confirm/ss-confirm.component';
import { SsFinishComponent } from '@app/self-service/ss-finish/ss-finish.component';
import { SsOrderComponent } from '@app/self-service/ss-order/ss-order.component';
import { SsStartComponent } from '@app/self-service/ss-start/ss-start.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'home', component: HomeComponent },
  {
    path: 'bar',
    component: SalesComponent,
    children: [{ path: 'choose_toppings/:prodId', component: ChooseToppingsComponent, outlet: 'modal' }]
  },
  { path: 'snackbar', component: SnackbarComponent },
  { path: 'users', component: UsersComponent },
  { path: 'users/edit_user/:id', component: UsersEditComponent },
  { path: 'users/create_user', component: UsersCreateComponent },
  { path: 'cards', component: CardsComponent },
  { path: 'cards/create_card', component: CardsCreateComponent },
  { path: 'cards/edit_card/:id', component: CardsEditComponent },
  { path: 'permissions', component: PermissionsComponent },
  { path: 'groups', component: GroupsComponent },
  { path: 'groups/create_group', component: GroupsCreateComponent },
  { path: 'groups/edit_group/:id', component: GroupsEditComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'products/create_product', component: ProductsCreateComponent },
  { path: 'products/edit_product/:id', component: ProductsEditComponent },
  { path: 'categories', component: CategoriesComponent },
  { path: 'categories/create_category', component: CategoriesCreateComponent },
  { path: 'categories/edit_category/:id', component: CategoriesEditComponent },
  { path: 'meals', component: MealsComponent },
  { path: 'goods-export', component: GoodsExportComponent },
  { path: 'goods-export/create-export-order', component: GoodsExportCreateComponent },
  { path: 'goods', component: GoodsComponent },
  { path: 'goods/create-order', component: GoodsCreateComponent },
  { path: 'finance', component: MutationsComponent },
  { path: 'transactions', component: TransactionsComponent },
  { path: 'transactions/transactions_export', component: TransactionsExportComponent },
  { path: 'security', component: PresencesComponent },
  { path: 'check-in-check-out', component: CheckInCheckOutComponent },
  { path: 'first_aid', component: FirstAidComponent },
  { path: 'settings', component: SettingsComponent },
  {
    path: 'self-service',
    component: SelfServiceComponent,
    children: [
      { path: 'start', component: SsStartComponent, outlet: 'self_service' },
      { path: 'order', component: SsOrderComponent, outlet: 'self_service' },
      { path: 'confirm', component: SsConfirmComponent, outlet: 'self_service' },
      { path: 'finish', component: SsFinishComponent, outlet: 'self_service' },
      { path: 'choose_toppings/:prodId', component: ChooseToppingsComponent, outlet: 'modal' }
    ]
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' }
];
@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule {}
