import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ScanService } from '@app/services/scan.service';
import { CardService } from '@app/services/card.service';
import { UserService } from '@app/services/user.service';
import { RegistrationService } from '@app/services/registration.service';
declare var $: any;

@Component({
  selector: 'app-check-in-check-out',
  templateUrl: './check-in-check-out.component.html',
  styleUrls: ['./check-in-check-out.component.scss']
})
export class CheckInCheckOutComponent implements OnInit, OnDestroy {
  public user;
  public logs;
  public env = environment;
  public searchTime: any;
  public query = '';
  public scanObserver;
  constructor(
    public cardService: CardService,
    public userService: UserService,
    public router: Router,
    public location: Location,
    public registrationService: RegistrationService,
    public route: ActivatedRoute,
    public scanService: ScanService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.reset();
      this.getUser();
    });

    this.scanObserver = this.scanService.getObserver().subscribe(scan_code => {
      this.query = scan_code;
      this.search();
    });

    $('input').click(function(e) {
      $(this).select();
    });

    $('input[type=text]').keydown(function(e) {
      if (e.which === 13) {
        $(this).select();
      }
    });
  }

  ngOnDestroy() {
    this.scanService.setHandleFocus(false);
    this.scanObserver.unsubscribe();
  }

  isPresent() {
    if (!this.user || !this.logs || this.logs.length === 0) {
      return null;
    }

    return this.logs[0].state === 'present';
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.getUser();
    }, 300);
  }

  reset() {
    this.user = undefined;
    this.logs = [];
  }

  getUser() {
    if (!this.query) {
      return;
    }

    this.cardService.getByScanCode(this.query).subscribe(card => {
      this.setUser(card.user);
    });
  }

  setUser(user) {
    this.user = user;
    this.registrationService.getByUser(user._id).subscribe(logs => (this.logs = logs));
  }

  markPresent() {
    this.registrationService
      .create({
        user: this.user,
        state: 'present'
      })
      .subscribe(e => {
        this.setUser(this.user);
      });
  }

  markAway() {
    this.registrationService
      .create({
        user: this.user,
        state: 'away'
      })
      .subscribe(e => {
        this.setUser(this.user);
      });
  }
}
