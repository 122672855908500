import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { UserService } from '@app/services/user.service';
import { environment } from 'environments/environment';
import { TransactionService } from '@app/services/transaction.service';

@Component({
  selector: 'app-transactions-export',
  templateUrl: './transactions-export.component.html',
  styleUrls: ['./transactions-export.component.scss']
})
export class TransactionsExportComponent implements OnInit {
  public env = environment;

  public dateFrom;
  public timeFrom;
  public dateTo;
  public timeTo;

  public validation = {
    dateFrom: true,
    timeFrom: true,
    dateTo: true,
    timeTo: true
  };

  public filter = {
    user: undefined,
    transaction_type: {
      deposit: false,
      withdraw: false,
      set: false,
      payment: true
    },
    balance_type: {
      debit: true,
      credit: true,
      credit_ceil: false,
      compensation: true
    }
  };

  constructor(public userService: UserService, public transactionService: TransactionService) {}

  ngOnInit() {
    this.dateFrom = moment('11-07-2018', 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.timeFrom = '00:00';
    this.dateTo = moment('17-07-2018', 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.timeTo = '23:59';
  }

  export(print = false) {
    this.validation.dateFrom = moment(this.dateFrom, 'YYYY-MM-DD').isValid();
    this.validation.timeFrom = moment(this.timeFrom, 'HH:mm').isValid();
    this.validation.dateTo = moment(this.dateTo, 'YYYY-MM-DD').isValid();
    this.validation.timeTo = moment(this.timeTo, 'HH:mm').isValid();

    if (this.validation.dateFrom && this.validation.timeFrom && this.validation.dateTo && this.validation.timeTo) {
      const transaction_types = [];

      if (this.filter.transaction_type.deposit) {
        transaction_types.push('deposit');
      }

      if (this.filter.transaction_type.withdraw) {
        transaction_types.push('withdraw');
      }

      if (this.filter.transaction_type.set) {
        transaction_types.push('set');
      }

      if (this.filter.transaction_type.payment) {
        transaction_types.push('payment');
      }

      const balance_types = [];

      if (this.filter.balance_type.debit) {
        balance_types.push('debit');
      }

      if (this.filter.balance_type.credit) {
        balance_types.push('credit');
      }

      if (this.filter.balance_type.credit_ceil) {
        balance_types.push('credit_ceil');
      }

      if (this.filter.balance_type.compensation) {
        balance_types.push('compensation');
      }

      const query: any = {
        $and: [
          { date: { $gte: new Date(moment(this.dateFrom + ' ' + this.timeFrom, 'YYYY-MM-DD HH:mm').toISOString()) } },
          { date: { $lte: new Date(moment(this.dateTo + ' ' + this.timeTo, 'YYYY-MM-DD HH:mm').toISOString()) } }
        ],
        transaction_type: transaction_types,
        balance_type: balance_types
      };

      if (this.filter.user) {
        query.user = this.filter.user;
      }

      if (print) {
        this.transactionService.print({ query: query, limit: Number.MAX_SAFE_INTEGER });
      } else {
        this.transactionService.export({ query: query, limit: Number.MAX_SAFE_INTEGER });
      }
    }
  }
}
