import { Injectable } from '@angular/core';
import { AlertService } from '@app/services/alert.service';
import { API } from '@app/services/api.service';
import { BaseService } from '@app/services/base.service';

@Injectable()
export class ProductService extends BaseService {
  constructor(public api: API, public alertService: AlertService) {
    super(api, alertService);
    this.route = 'products';
  }

  getByScanCode(scan_code, options?) {
    return this.api.get(this.route + '/scan/' + scan_code, {}, {}, options);
  }

  print() {
    window.open(this.api.url + this.route + '/print?token=' + localStorage.getItem('token'));
  }
}
