import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';
import { ProductService } from '@app/services/product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ScanService } from '@app/services/scan.service';
import { AlertService } from '@app/services/alert.service';
import { Subscription } from 'rxjs';
import { OrderService } from '@app/services/order.service';
import { AuthService } from '@app/services/auth.service';
import { SettingsService } from '@app/services/settings.service';
declare var $: any;

@Component({
  selector: 'app-goods',
  templateUrl: './goods.component.html',
  styleUrls: ['./goods.component.scss']
})
export class GoodsComponent implements OnInit, OnDestroy {
  public searchTime: any;
  public skip;
  public limit;
  public orders: any = [];
  public query = '';
  public selectedOrder;
  public env = environment;
  public to_order;
  public sub: Subscription;

  constructor(
    public orderService: OrderService,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public productService: ProductService,
    public authService: AuthService,
    public settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.refresh();
    });

    this.settingsService.get().subscribe(settings => (this.to_order = settings.to_order || []));
  }

  ngOnDestroy() {
    this.refresh();
  }

  refresh() {
    this.reset();
    this.fetchNext();
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.to_order = [];

    const height = 64;
    const columns = 1;

    this.limit = Math.ceil((window.innerHeight / height) * columns);
    this.orders = [];
    this.skip = 0;
    this.selectedOrder = null;
  }

  fetchNext() {
    this.sub = this.orderService
      .get({
        query: {
          type: 'goods'
        },
        skip: this.skip,
        limit: this.limit
      })
      .subscribe(orders => {
        this.orders = this.orders.concat(orders);

        if (!this.selectedOrder) {
          this.setOrder(this.orders[0]);
        }
      });

    this.skip += this.limit;
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.fetchNext();
    }, 300);
  }

  setOrder(order) {
    this.selectedOrder = order;
  }

  create() {
    this.router.navigate(['goods', 'create-order']);
  }

  addProductToOrder(product) {
    for (let i = 0; i < this.to_order.length; i++) {
      const orderLine = this.to_order[i];
      if (String(orderLine.product._id) === String(product._id)) {
        orderLine.quantity++;
        this.save();
        return;
      }
    }

    this.to_order.push({ product: product, quantity: 1 });
    this.save();
  }

  removeProductFromToOrder(product) {
    for (let i = 0; i < this.to_order.length; i++) {
      const orderLine = this.to_order[i];
      if (String(orderLine.product._id) === String(product._id)) {
        orderLine.quantity--;

        if (orderLine.quantity < 1) {
          this.to_order.splice(i, 1);
        }

        this.save();
        return;
      }
    }
  }

  save() {
    for (let i = 0; i < this.to_order.length; i++) {
      const orderLine = this.to_order[i];

      if (orderLine.quantity && orderLine.quantity < 1) {
        this.to_order.splice(i, 1);
        return;
      }
    }

    this.settingsService.get().subscribe(settings => {
      settings.to_order = this.to_order;
      this.settingsService.save(settings);
    });
  }
}
