import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { UserService } from '@app/services/user.service';
import { AuthService } from '@app/services/auth.service';
declare var $: any;

const PROFILE_IMAGE = '/assets/images/default_profile.jpg';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit, OnDestroy {
  public user;
  public impersonate_disabled;
  public id;
  public validation = {
    profileImage: true,
    fullName: true,
    dob: true
  };

  constructor(
    public route: ActivatedRoute,
    public userService: UserService,
    public location: Location,
    public router: Router,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.reset();
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getUser();
    });
  }

  ngOnDestroy() {
    this.reset();
    this.getUser();
  }

  getUser() {
    this.userService.getById(this.id).subscribe(user => {
      this.user = user;
      this.user.password = '';
      this.user.dob = moment(this.user.dob).format('YYYY-MM-DD');

      this.impersonate_disabled = !user.email;

      $('.profile-image-container').css(
        'background-image',
        'url(' + environment.IP_ADDRESS + '/api/upload/' + this.user.profileImage + '.jpg)'
      );
      $('#profileImageUpload').val('');
    });
  }

  reset() {
    this.user = {
      profileImage: PROFILE_IMAGE,
      duty: 1,
      gender: 'male',
      dob: moment()
        .subtract(12, 'years')
        .format('YYYY-MM-DD'),
      active: true,
      language: 'nl-NL',
      night_mode_setting: 'auto'
    };

    $('.profile-image-container').css('background-image', 'url(' + PROFILE_IMAGE + ')');
    $('#profileImageUpload').val('');
  }

  showProfileImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (event2: any) => {
        this.user.profileImage = event2.target.result;
        $('.profile-image-container').css('background-image', 'url(' + this.user.profileImage + ')');
        $('#profileImageUpload').val('');
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  save() {
    this.validation.profileImage = this.user.profileImage !== PROFILE_IMAGE;
    this.validation.fullName = !!this.user.fullName;
    this.validation.dob = !!this.user.dob;

    if (this.validation.profileImage && this.validation.fullName && this.validation.dob) {
      this.userService.update(this.user).subscribe(() => this.router.navigate(['users']));
    }
  }

  impersonate() {
    this.authService.impersonate(this.user);
  }
}
