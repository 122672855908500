import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { API } from '@app/services/api.service';
import { AlertService } from '@app/services/alert.service';

@Injectable()
export class BroadcastService extends BaseService {
  constructor(public api: API, public alertService: AlertService) {
    super(api, alertService);
    this.route = 'broadcasts';
  }

  read(id) {
    return this.api.post(this.route + '/read/' + id, {});
  }
}
