import { Component, OnInit, Input } from '@angular/core';
import { DeleteService } from '@app/services/delete.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  constructor(public deleteService: DeleteService) {}

  ngOnInit() {}

  confirm() {
    this.deleteService.confirm();
  }
}
