import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { CardService } from '@app/services/card.service';
import { UserService } from '@app/services/user.service';
declare var $: any;

@Component({
  selector: 'app-cards-create',
  templateUrl: './cards-create.component.html',
  styleUrls: ['./cards-create.component.scss']
})
export class CardsCreateComponent implements OnInit, OnDestroy {
  public env = environment;
  public id;
  public card;
  public validation = {
    user: true
  };

  constructor(public cardService: CardService, public userService: UserService, public route: ActivatedRoute, public router: Router) {}

  ngOnInit() {
    this.reset();
  }
  ngOnDestroy() {
    this.reset();
  }

  reset() {
    this.card = {};
  }

  save() {
    this.validation.user = !!this.card.user;

    if (this.validation.user) {
      this.cardService.create(this.card).subscribe(() => this.router.navigate(['cards']));
    }
  }
}
