import { Component, OnInit, OnDestroy } from '@angular/core';
import { TransactionService } from '@app/services/transaction.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  public searchTime;
  public skip;
  public limit;
  public transactions;
  public query = '';
  public env = environment;
  public sub: Subscription;

  constructor(
    public transactionService: TransactionService,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.reset();
      this.fetchNext();
    });
  }

  refresh() {
    this.reset();
    this.fetchNext();
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    const height = 50;
    const columns = 1;

    this.limit = Math.ceil((window.innerHeight / height) * columns);
    this.transactions = [];
    this.skip = 0;
  }

  fetchNext() {
    this.sub = this.transactionService
      .get({
        search: this.query,
        skip: this.skip,
        limit: this.limit
      })
      .subscribe(transactions => (this.transactions = this.transactions.concat(transactions)));

    this.skip += this.limit;
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.fetchNext();
    }, 300);
  }

  export() {
    this.router.navigate(['transactions', 'transactions_export']);
  }
}
