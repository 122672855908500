import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { CategoryService } from '@app/services/category.service';
import { AuthService } from '@app/services/auth.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {
  public searchTime: any;
  public skip: number;
  public limit: number;
  public categories: any = [];
  public query = '';
  public env = environment;
  public sub: Subscription;

  constructor(
    public categoryService: CategoryService,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.reset();
      this.fetchNext();
    });
  }

  ngOnDestroy() {
    this.refresh();
  }

  refresh() {
    this.reset();
    this.fetchNext();
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    const height = 270;
    const columns = 3;

    this.limit = Math.ceil((window.innerHeight / height) * columns);
    this.categories = [];
    this.skip = 0;
  }

  fetchNext() {
    this.sub = this.categoryService
      .get({
        search: this.query,
        skip: this.skip,
        limit: this.limit
      })
      .subscribe(categories => (this.categories = this.categories.concat(categories)));

    this.skip += this.limit;
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.fetchNext();
    }, 300);
  }

  create() {
    this.router.navigate(['categories', 'create_category']);
  }

  edit(category) {
    this.router.navigate(['categories', 'edit_category', category._id]);
  }

  delete(category) {
    this.categoryService.delete(category).subscribe(() => {
      for (let i = 0; i < this.categories.length; i++) {
        const e = this.categories[i];
        if (category._id === e._id) {
          this.categories.splice(i, 1);
          return;
        }
      }
    });
  }
}
