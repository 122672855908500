import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '@app/services/auth.service';
import { CardService } from '@app/services/card.service';
declare var $: any;

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit, OnDestroy {
  public env = environment;
  public searchTime: any;
  public skip: number;
  public limit: number;
  public cards: any = [];
  public query = '';
  public sub: Subscription;

  constructor(
    public cardService: CardService,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.refresh();
    });

    $('input[type=text]').on('keydown', function(e) {
      if (e.which === 13) {
        $(this).select();
      }
    });
  }

  ngOnDestroy() {
    this.refresh();
  }

  refresh() {
    this.reset();
    this.fetchNext();
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    const height = 120;
    const columns = 2;

    this.limit = Math.ceil((window.innerHeight / height) * columns);
    this.cards = [];
    this.skip = 0;
  }

  fetchNext() {
    this.sub = this.cardService
      .get({
        search: this.query,
        skip: this.skip,
        limit: this.limit
      })
      .subscribe(cards => (this.cards = this.cards.concat(cards)));

    this.skip += this.limit;
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.fetchNext();
    }, 300);
  }

  create() {
    this.router.navigate(['cards', 'create_card']);
  }

  edit(card) {
    this.router.navigate(['cards', 'edit_card', card._id]);
  }

  delete(card) {
    this.cardService.delete(card).subscribe(() => {
      for (let i = 0; i < this.cards.length; i++) {
        const e = this.cards[i];
        if (card._id === e._id) {
          this.cards.splice(i, 1);
          return;
        }
      }
    });
  }
}
