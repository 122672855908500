import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SalesService } from '@app/services/sales.service';

@Component({
  selector: 'app-ss-start',
  templateUrl: './ss-start.component.html',
  styleUrls: ['./ss-start.component.scss']
})
export class SsStartComponent implements OnInit {
  constructor(public router: Router, public salesService: SalesService) {}

  ngOnInit() {}

  next() {
    this.salesService.reset();
    this.router.navigate(['/self-service', { outlets: { self_service: 'order' } }]);
  }
}
