import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { ScanService } from '@app/services/scan.service';
import { CategoryService } from '@app/services/category.service';
import { ProductService } from '@app/services/product.service';
import { SalesService } from '@app/services/sales.service';
import { UserService } from '@app/services/user.service';
declare var $: any;

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit, OnDestroy {
  public categories;
  public selectedCategory;
  public products = [];
  public scanObserver: Subscription;
  public env = environment;

  constructor(
    public scanService: ScanService,
    public router: Router,
    public salesService: SalesService,
    public userService: UserService,
    public productService: ProductService,
    public categoryService: CategoryService
  ) {}

  ngOnInit() {
    this.reset();

    this.scanService.setHandleFocus(true);
    this.scanObserver = this.scanService.getObserver().subscribe(scan_code => {
      this.handleProductScan(scan_code);
    });
  }

  ngOnDestroy() {
    this.scanService.setHandleFocus(false);
    this.scanObserver.unsubscribe();
  }

  handleProductScan(scan_code) {
    this.productService
      .getByScanCode(scan_code, {
        ignoreErrors: true
      })
      .subscribe(
        product => {
          product.quantity = 1;
          this.salesService.addProduct(product);
        },
        () => this.salesService.handleCardScan(scan_code)
      );
  }

  reset() {
    this.salesService.reset();
    this.getCategories();
  }

  setCategory(category) {
    this.selectedCategory = category;
    this.products = category.products;
  }

  confirmOrder() {
    this.salesService.confirmOrder().then(() => this.reset());
  }

  getCategories() {
    this.categoryService.get({ query: { isSalesCat: true } }).subscribe(categories => {
      this.categories = categories;

      if (!this.selectedCategory) {
        this.setCategory(categories[0]);
      } else {
        for (let i = 0; i < categories.length; i++) {
          const category = categories[i];

          if (category._id === this.selectedCategory._id) {
            this.setCategory(category);
            break;
          }

          if (i === categories.length - 1) {
            this.setCategory(categories[0]);
          }
        }
      }
    });
  }
}
