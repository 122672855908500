import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/services/user.service';
import { AuthService } from '@app/services/auth.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  public searchTime: any;
  public skip: number;
  public limit: number;
  public users: any = [];
  public query = '';
  public sub: Subscription;

  constructor(
    public userService: UserService,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.reset();
      this.fetchNext();
    });
  }

  ngOnDestroy() {
    this.refresh();
  }

  refresh() {
    this.reset();
    this.fetchNext();
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    const height = 178;
    const columns = 2;

    this.limit = Math.ceil((window.innerHeight / height) * columns);
    this.users = [];
    this.skip = 0;
  }

  fetchNext() {
    this.sub = this.userService
      .get({
        query: {
          deleted: false
        },
        search: this.query,
        skip: this.skip,
        limit: this.limit
      })
      .subscribe(users => (this.users = this.users.concat(users)));

    this.skip += this.limit;
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.fetchNext();
    }, 300);
  }

  create() {
    this.router.navigate(['users', 'create_user']);
  }

  edit(user) {
    this.router.navigate(['users', 'edit_user', user._id]);
  }

  delete(user) {
    this.userService.delete(user).subscribe(() => {
      for (let i = 0; i < this.users.length; i++) {
        const e = this.users[i];
        if (user._id === e._id) {
          this.users.splice(i, 1);
          return;
        }
      }
    });
  }
}
