import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LoadingService } from '@app/services/loading.service';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit {
  @Input() bottom: Boolean;
  @Input() fixed: Boolean;

  constructor(public loading: LoadingService) {}

  ngOnInit() {}
}
