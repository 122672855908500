import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  public env = environment;

  @Input()
  user: any;
  @Input()
  render: Boolean = true;

  constructor() {}

  ngOnInit() {}

  isActive() {
    if (!this.user || this.user.active === undefined) {
      return true;
    }

    return this.user.active;
  }

  getGroups() {
    if (!this.user) {
      return 'USERS.NO_GROUPS';
    }

    let groups = '';

    this.user.groups.forEach(group => {
      groups += group + ', ';
    });

    if (groups.length === 0) {
      return 'USERS.NO_GROUPS';
    }

    return groups.substring(0, groups.length - 2);
  }

  getCSSClasses() {
    let classes = 'member-card-line';

    if (!this.user || !this.user.groups) {
      return classes;
    }

    if (this.user.groups.includes('Oefenleiding')) {
      classes += ' level-4';
    } else if (this.user.duty === 3) {
      classes += ' level-3';
    } else if (this.user.duty === 2) {
      classes += ' level-2';
    } else if (this.user.duty === 1) {
      classes += ' level-1';
    } else {
      classes += ' level-5';
    }

    return classes;
  }
}
