import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { LoadingService } from '@app/services/loading.service';

export class Alert {
  public message: String;
  public timeout: number;
  public shouldShow: Boolean = false;
  public type: String = 'danger';
  public timer;

  constructor(public alertService: AlertService) {}

  show() {
    this.shouldShow = true;

    clearTimeout(this.timer);

    if (this.timeout > 0) {
      this.timeout = setTimeout(() => {
        this.hideAlert();
      }, this.timeout);
    }
  }

  hideAlert(): any {
    this.shouldShow = false;

    setTimeout(() => {
      this.alertService.removeAlert(this);
    }, 200);
  }
}

@Injectable()
export class AlertService {
  public alerts: Alert[] = [];

  constructor(public loadingService: LoadingService, public http: HttpClient) {}

  showError(err, timeout = 5000) {
    const alert = new Alert(this);
    alert.message = err;
    alert.timeout = timeout;
    alert.type = 'danger';
    alert.show();
    this.alerts.push(alert);
  }

  showWarning(warning, timeout = 5000) {
    const alert = new Alert(this);
    alert.message = warning;
    alert.timeout = timeout;
    alert.type = 'warning';
    alert.show();
    this.alerts.push(alert);
  }

  showSuccess(success, timeout = 5000) {
    const alert = new Alert(this);
    alert.message = success;
    alert.timeout = timeout;
    alert.type = 'success';
    alert.show();
    this.alerts.push(alert);
  }

  showInfo(info, timeout = 5000) {
    const alert = new Alert(this);
    alert.message = info;
    alert.timeout = timeout;
    alert.type = 'info';
    alert.show();
    this.alerts.push(alert);
  }

  getAlerts(): Alert[] {
    return this.alerts;
  }

  handleError(err, timeout = 5000) {
    this.loadingService.hideLoader();
    err = err.error.message || 'UNKNOWN_ERROR';
    this.showError(err, timeout);

    console.error(JSON.stringify(err));

    if (environment.ENV !== 'dev') {
      const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : { fullName: 'Unknown' };

      const keys = [
        'ujnftxjws4s3vfst7h2y84612sh6dk', // Stef
        'upueuo6wehq39r9j21te2kyhc9b8uz', // Dylan
        'uztxt1udq9ytjz9krnszw7rp8jifmx' // Jorn
      ];

      keys.forEach(key => {
        this.http
          .post('https://api.pushover.net/1/messages.json', {
            token: 'a38997uepj86axp2vn5os4u7okefwe',
            user: key,
            title: 'KJO-POS: Error Received',
            message: `${user.fullName} received an error: ${err} at POS terminal ${localStorage.getItem('terminal') || 'unknown'}`,
            sound: 'intermission'
          })
          .subscribe();
      });
    }
  }

  removeAlert(alert: any): any {
    this.alerts.forEach((e, i) => {
      if (e === alert) {
        this.alerts.splice(i, 1);
      }
    });
  }

  removeAlerts() {
    this.alerts = [];
  }
}
