import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { NightModeService } from '@app/services/night-mode.service';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';

export enum CollapsedStatus {
  open = 'open',
  closed = 'closed'
}

export class MenuItem {
  title: string;
  icon: string;
  link: string;
  permission?: string[];
  sub_items?: MenuItem[];
}

@Component({
  selector: 'app-nav-side-menu',
  templateUrl: './nav-side-menu.component.html',
  styleUrls: ['./nav-side-menu.component.scss'],
  animations: [
    trigger('collapse', [
      state(
        'open',
        style({
          transform: 'translate3D(0, 0, 0)'
        })
      ),
      state(
        'closed',
        style({
          transform: 'translate3D(-99%, 0, 0)'
        })
      ),
      transition('closed => open', animate('500ms ease-in')),
      transition('open => closed', animate('500ms ease-out'))
    ])
  ]
})
export class NavSideMenuComponent implements OnInit, OnDestroy {
  @Output() collapsed$: EventEmitter<CollapsedStatus> = new EventEmitter();

  public env = environment;
  collapsed: CollapsedStatus = CollapsedStatus.open;
  hover: MenuItem | string;
  url: UrlSegment[];
  url$: Subscription;

  menu_items: MenuItem[] = [
    {
      title: 'HOME',
      icon: 'home',
      link: '/home'
    },
    {
      title: 'SALES',
      icon: 'hand-holding-usd',
      link: '/bar',
      permission: ['bar*'],
      sub_items: [{ title: 'SNACKBAR', icon: 'french-fries', link: '/snackbar' }]
    },
    {
      title: 'USERS',
      icon: 'users',
      link: '/users',
      permission: ['users*', 'cards*', 'groups*', 'permissions*'],
      sub_items: [
        { title: 'CARDS', icon: 'id-card', link: '/cards' },
        { title: 'GROUPS', icon: 'users-cog', link: '/groups' },
        { title: 'PERMISSIONS', icon: 'key', link: '/permissions' }
      ]
    },
    {
      title: 'PRODUCTS',
      icon: 'cubes',
      link: '/products',
      permission: ['products*', 'categories*'],
      sub_items: [
        { title: 'CATEGORIES', icon: 'pallet-alt', link: '/categories' }
        // { title: 'WAREHOUSES', icon: 'inventory', link: '/warehouses' }
      ]
    },
    {
      title: 'MEALS',
      icon: 'utensils-alt',
      link: '/meals',
      permission: ['kitchen*'],
      sub_items: [
        { title: 'GOODS', icon: 'truck-loading', link: '/goods' },
        { title: 'GOODS-EXPORT', icon: 'hand-holding-box', link: '/goods-export' }
      ]
    },
    {
      title: 'FINANCE',
      icon: 'money-bill-wave',
      link: '/finance',
      permission: ['finance*'],
      sub_items: [
        {
          title: 'TRANSACTIONS',
          icon: 'exchange',
          link: '/transactions'
        }
      ]
    },
    {
      title: 'SECURITY',
      icon: 'user-shield',
      link: '/security',
      permission: ['security*', 'presences*'],
      sub_items: [{ title: 'CHECK-IN-CHECK-OUT', icon: 'bed', link: '/check-in-check-out' }]
    },
    { title: 'FIRST-AID', icon: 'medkit', link: '/first_aid', permission: ['first_aid'] },
    {
      title: 'SETTINGS',
      icon: 'cogs',
      link: '/settings'
    }
  ];

  constructor(public authService: AuthService, public router: Router, public nightModeService: NightModeService) {
    const collapsed_storage = localStorage.getItem('side_menu_collapsed');

    if (collapsed_storage == null) {
      this.collapsed = window.innerWidth > window.innerHeight ? CollapsedStatus.open : CollapsedStatus.closed;
    } else {
      if (collapsed_storage === CollapsedStatus.closed) {
        this.collapsed = CollapsedStatus.closed;
      } else {
        this.collapsed = CollapsedStatus.open;
      }
    }

    this.nightModeService.getMode();
  }

  ngOnInit() {
    this.collapsed$.emit(this.collapsed);

    $('.side-menu-icons ul').on('scroll', () => {
      $('.side-menu-buttons ul').scrollTop($('.side-menu-icons ul').scrollTop());
    });

    $('.side-menu-buttons ul').on('scroll', () => {
      $('.side-menu-icons ul').scrollTop($('.side-menu-buttons ul').scrollTop());
    });
  }

  ngOnDestroy() {
    this.collapsed$.unsubscribe();
  }

  getProfileImage() {
    if (!localStorage.getItem('user')) {
      return '/assets/images/default_profile.jpg';
    }

    return this.env.IP_ADDRESS + '/api/upload/' + JSON.parse(localStorage.getItem('user')).profileImage;
  }

  sign_out() {
    this.authService.logout();
    this.router.navigate(['auth', 'sign_in']);
  }

  toggle_collapse() {
    switch (this.collapsed) {
      case CollapsedStatus.open:
        this.collapsed = CollapsedStatus.closed;
        break;
      case CollapsedStatus.closed:
        this.collapsed = CollapsedStatus.open;
        break;
    }

    localStorage.setItem('side_menu_collapsed', this.collapsed);

    this.collapsed$.emit(this.collapsed);
  }

  isActive(menu_item: MenuItem) {
    const url = this.router.url;
    if (url.match(new RegExp(menu_item.link.replace(':id', '') + '.*'))) {
      return true;
    }

    if (menu_item.sub_items) {
      for (let index = 0; index < menu_item.sub_items.length; index++) {
        const sub_item = menu_item.sub_items[index];

        if (url.match(new RegExp(sub_item.link.replace(':id', '') + '.*'))) {
          return true;
        }
      }
    }

    return false;
  }

  hasPermission(menu_item: MenuItem) {
    if (menu_item.permission) {
      for (let index = 0; index < menu_item.permission.length; index++) {
        const permission = menu_item.permission[index];
        if (this.authService.hasPermission(permission)) {
          return true;
        }
      }

      return false;
    } else {
      return true;
    }
  }
}
