import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AppService } from '@app/services/app.service';
import { AuthService } from '@app/services/auth.service';
import { LoadingService } from '@app/services/loading.service';
import { UserIdleService } from 'angular-user-idle';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CollapsedStatus } from './components/nav-side-menu/nav-side-menu.component';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('collapse', [
      state(
        'open',
        style({
          'margin-left': '225px',
          'max-width': 'calc(100% - 225px)'
        })
      ),
      state(
        'closed',
        style({
          'margin-left': '50px',
          'max-width': 'calc(100% - 50px)'
        })
      ),
      transition('closed => open', animate('500ms ease-in')),
      transition('open => closed', animate('500ms ease-out'))
    ])
  ]
})
export class AppComponent {
  title = 'app';
  public env = environment;
  public w = window;
  public countdown;
  collapsed: CollapsedStatus = CollapsedStatus.open;

  constructor(
    public router: Router,
    public http: HttpClient,
    public loadingService: LoadingService,
    public authService: AuthService,
    public appService: AppService,
    public userIdle: UserIdleService
  ) {
    $(window).scroll(function() {
      if ($(this).scrollTop() < 100) {
        $('.scroll-top-btn').fadeOut();
      } else {
        $('.scroll-top-btn').fadeIn();
      }
    });

    this.registerShortcuts();

    this.userIdle.startWatching();

    this.userIdle.onTimerStart().subscribe(count => {
      if (this.authService.getUser() && this.authService.getUser().remember_me) {
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        return;
      }

      this.countdown = 30 - count;

      if (count) {
        $('#modal-inactivity').modal('show');
      }
    });

    this.userIdle.onTimeout().subscribe(() => {
      $('#modal-inactivity').modal('hide');
      this.authService.logout();
    });

    this.userIdle.ping$.subscribe(() => {
      if (this.router.url === '/login' || (this.authService.getUser() && this.authService.getUser().remember_me)) {
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        return;
      }
    });

    if (localStorage.getItem('default_page') !== null) {
      this.router.navigate(JSON.parse(localStorage.getItem('default_page')));
    }
  }

  scrollTop() {
    $('html').animate({ scrollTop: 0 }, 500);
  }

  registerShortcuts() {
    window.addEventListener('keydown', event => {
      if (!localStorage.getItem('user')) {
        return;
      }

      switch (event.keyCode) {
        // F4
        case 115:
          this.authService.logout();
          break;
        // F5
        case 116:
          location.reload();
          break;
        // F6
        case 117:
          $('.btn-success').click();
          break;
        // F7
        case 118:
          $('.btn-outline-secondary').click();
          break;
        // R
        case 82:
          if (!$('input[type=text], .nav-item, button').is(':focus')) {
            $('.fa-sync-alt').click();
          }
          break;
        // F12
        case 123:
          this.appService.requestHelp();
          break;
        // default:
        //   console.log('Pressed: ' + event.keyCode);
        //   break;
      }
    });
  }
}
