import { Component, OnInit, OnDestroy } from '@angular/core';
import { SalesService } from '@app/services/sales.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ScanService } from '@app/services/scan.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ss-confirm',
  templateUrl: './ss-confirm.component.html',
  styleUrls: ['./ss-confirm.component.scss']
})
export class SsConfirmComponent implements OnInit, OnDestroy {
  public scanObserver: Subscription;
  public env = environment;

  constructor(public salesService: SalesService, public router: Router, public scanService: ScanService) {}

  ngOnInit() {
    this.scanService.setHandleFocus(true);
    this.scanObserver = this.scanService.getObserver().subscribe(scan_code => {
      this.salesService.handleCardScan(scan_code).then(() => {
        this.salesService.confirmOrder().then(() => {
          this.router.navigate(['/self-service', { outlets: { self_service: 'finish' } }]);
        });
      });
    });
  }

  ngOnDestroy() {
    this.scanService.setHandleFocus(false);
    this.scanObserver.unsubscribe();
  }

  cancel() {
    this.router.navigate(['/self-service', { outlets: { self_service: 'order' } }]);
  }
}
