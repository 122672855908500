import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { PresencesService } from '@app/services/presences.service';
import { AuthService } from '@app/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-presences',
  templateUrl: './presences.component.html',
  styleUrls: ['./presences.component.scss']
})
export class PresencesComponent implements OnInit {
  public limit;
  public skip;
  public query = '';
  public selectedPresence;
  public presences = [];
  public searchTime;
  public global_amounts;
  public env = environment;
  public sub: Subscription;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    public presencesService: PresencesService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.reset();
      this.fetchNext();
      this.getGlobalAmounts();
    });
  }

  refresh() {
    this.reset();
    this.fetchNext();
    this.getGlobalAmounts();
  }

  getGlobalAmounts() {
    this.presencesService.status().subscribe(global_amounts => (this.global_amounts = global_amounts));
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    const height = 50;
    const columns = 1;

    this.limit = Math.ceil((window.innerHeight / height) * columns);
    this.presences = [];
    this.skip = 0;

    this.selectedPresence = null;
  }

  fetchNext() {
    this.sub = this.presencesService
      .get({
        search: this.query,
        skip: this.skip,
        limit: this.limit
      })
      .subscribe(users => {
        this.presences = this.presences.concat(users);
        if (!this.selectedPresence && this.presences.length > 0) {
          this.setPresence(this.presences[0]._id);
        }
      });

    this.skip += this.limit;
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.fetchNext();
    }, 300);
  }

  cancel() {
    this.setPresence(this.selectedPresence._id);
  }

  save() {
    this.presencesService.update(this.selectedPresence).subscribe(() => this.getGlobalAmounts());
  }

  setPresence(id) {
    this.presencesService.getById(id).subscribe(presence => (this.selectedPresence = presence));
  }
}
