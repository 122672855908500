import { Injectable } from '@angular/core';
import { AlertService } from '@app/services/alert.service';
import { API } from '@app/services/api.service';
import { Observable } from 'rxjs';
@Injectable()
export abstract class BaseService {
  public route;

  constructor(public api: API, public alertService: AlertService) {}

  create(object): Observable<any> {
    return this.api.post(this.route, { object: object });
  }

  get(options?: { search?: String; query?: {}; skip?: number; limit?: number; exclude?: Object[] }): Observable<any> {
    if (options && options.query) {
      options.query = JSON.stringify(options.query);
    }

    return this.api.get(this.route, options);
  }

  getById(id): Observable<any> {
    return this.api.get(this.route + '/' + id);
  }

  update(object): Observable<any> {
    return this.api.put(this.route + '/' + object._id, { object: object });
  }

  delete(object): Observable<any> {
    return this.api.delete(this.route + '/' + object._id);
  }

  export(id) {
    window.open(this.api.url + this.route + '/' + id + '/export?token=' + localStorage.getItem('token'), 'export');
  }

  print(id) {
    window.open(this.api.url + this.route + '/' + id + '/print?token=' + localStorage.getItem('token'));
  }
}
