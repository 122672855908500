import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { GroupService } from '@app/services/group.service';
import { UserService } from '@app/services/user.service';
import { ALL_PERMISSIONS } from '@app/sections/administration/permissions/ALL_PERMISSIONS';
declare var $: any;

@Component({
  selector: 'app-groups-create',
  templateUrl: './groups-create.component.html',
  styleUrls: ['./groups-create.component.scss']
})
export class GroupsCreateComponent implements OnInit, OnDestroy {
  public group;
  public env = environment;
  public id;
  public validation = {
    name: true
  };
  public allPermissions = ALL_PERMISSIONS;

  constructor(
    public route: ActivatedRoute,
    public groupService: GroupService,
    public location: Location,
    public router: Router,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.reset();
  }

  ngOnDestroy() {
    this.reset();
  }

  reset() {
    this.group = {
      users: [],
      permissions: []
    };
  }

  save() {
    this.validation.name = !!this.group.name;

    if (this.validation.name) {
      this.groupService.create(this.group).subscribe(() => this.router.navigate(['groups']));
    }
  }

  addUser(user) {
    for (let i = 0; i < this.group.users.length; i++) {
      if (this.group.users[i]._id === user._id) {
        return;
      }
    }

    this.group.users.push(user);
  }

  removeUser(user) {
    for (let i = 0; i < this.group.users.length; i++) {
      if (this.group.users[i]._id === user._id) {
        this.group.users.splice(i, 1);
        return;
      }
    }
  }

  hasPermission(permission) {
    if (!this.group) {
      return false;
    }
    for (let i = 0; i < this.group.permissions.length; i++) {
      if (this.group.permissions[i] === permission) {
        return true;
      }
    }

    return false;
  }

  togglePermission(permission) {
    for (let i = 0; i < this.group.permissions.length; i++) {
      if (this.group.permissions[i] === permission) {
        this.group.permissions.splice(i, 1);
        return;
      }
    }

    this.group.permissions.push(permission);
  }
}
