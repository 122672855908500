import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GroupService } from '@app/services/group.service';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { AuthService } from '@app/services/auth.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit, OnDestroy {
  public searchTime: any;
  public skip: number;
  public limit: number;
  public groups: any = [];
  public query = '';
  public sub: Subscription;
  public env = environment;

  constructor(
    public groupService: GroupService,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.refresh();
    });
  }

  ngOnDestroy() {
    this.refresh();
  }

  refresh() {
    this.reset();
    this.fetchNext();
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    const height = 270;
    const columns = 3;

    this.limit = Math.ceil((window.innerHeight / height) * columns);
    this.groups = [];
    this.skip = 0;
  }

  fetchNext() {
    this.sub = this.groupService
      .get({ search: this.query, skip: this.skip, limit: this.limit })
      .subscribe(groups => (this.groups = this.groups.concat(groups)));

    this.skip += this.limit;
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.fetchNext();
    }, 300);
  }

  export(group) {
    this.groupService.export(group._id);
  }

  cardsExport(group) {
    this.groupService.cardsExport(group._id);
  }

  create() {
    this.router.navigate(['groups', 'create_group']);
  }

  edit(group) {
    this.router.navigate(['groups', 'edit_group', group._id]);
  }

  delete(group) {
    this.groupService.delete(group).subscribe(() => {
      for (let i = 0; i < this.groups.length; i++) {
        const e = this.groups[i];
        if (group._id === e._id) {
          this.groups.splice(i, 1);
          return;
        }
      }
    });
  }
}
