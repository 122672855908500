import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { DeleteService } from '@app/services/delete.service';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {
  @Input()
  type = '';
  @Output()
  editEvent = new EventEmitter();
  @Output()
  deleteEvent = new EventEmitter();

  constructor(public deleteService: DeleteService, public authService: AuthService) {}

  ngOnInit() {}

  edit(item) {
    this.editEvent.emit(item);
  }

  delete(item) {
    this.deleteService.delete(item, this.deleteEvent);
  }
}
