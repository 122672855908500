import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { UserService } from '@app/services/user.service';
import { AuthService } from '@app/services/auth.service';
import { Subscription } from 'rxjs';
import { ALL_PERMISSIONS } from '@app/sections/administration/permissions/ALL_PERMISSIONS';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {
  public limit: number;
  public skip: number;
  public query = '';
  public selectedUser;
  public users = [];
  public searchTime: any;
  public env = environment;
  public sub: Subscription;
  public allPermissions = ALL_PERMISSIONS;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    public authService: AuthService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.reset();
      this.fetchNext();
    });
  }

  refresh() {
    this.reset();
    this.fetchNext();
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    const height = 50;
    const columns = 1;

    this.limit = Math.ceil((window.innerHeight / height) * columns);
    this.users = [];
    this.skip = 0;

    this.selectedUser = null;
  }

  fetchNext() {
    this.sub = this.userService
      .get({
        search: this.query,
        skip: this.skip,
        limit: this.limit
      })
      .subscribe(users => {
        this.users = this.users.concat(users);
        if (!this.selectedUser) {
          this.setUser(this.users[0]);
        }
      });

    this.skip += this.limit;
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.fetchNext();
    }, 300);
  }

  cancel() {
    this.setUser(this.selectedUser);
  }

  save() {
    this.userService.update(this.selectedUser).subscribe();
  }

  setUser(user) {
    this.selectedUser = user;
    this.userService.getById(user._id).subscribe(data => (this.selectedUser = data));
  }

  hasPermission(permission) {
    if (!this.selectedUser) {
      return false;
    }

    for (let i = 0; i < this.selectedUser.permissions.length; i++) {
      if (this.selectedUser.permissions[i] === permission) {
        return true;
      }
    }

    return false;
  }

  togglePermission(permission) {
    for (let i = 0; i < this.selectedUser.permissions.length; i++) {
      if (this.selectedUser.permissions[i] === permission) {
        this.selectedUser.permissions.splice(i, 1);
        return;
      }
    }

    this.selectedUser.permissions.push(permission);
  }
}
