import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { AlertService } from '@app/services/alert.service';
import { ScanService } from '@app/services/scan.service';
import { CardService } from '@app/services/card.service';
import { MealsService } from '@app/services/meals.service';

@Component({
  selector: 'app-meals',
  templateUrl: './meals.component.html',
  styleUrls: ['./meals.component.scss']
})
export class MealsComponent implements OnInit, OnDestroy {
  public scanObserver: Subscription;
  public env = environment;
  public meals = [];
  public selectedMeal;
  public lastUser;

  constructor(
    public mealsService: MealsService,
    public scanService: ScanService,
    public cardService: CardService,
    public alertService: AlertService,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    this.getMeals();

    this.scanObserver = this.scanService.getObserver().subscribe(scan_code => {
      if (!this.selectedMeal) {
        return;
      }

      this.cardService.getByScanCode(scan_code).subscribe(card => {
        if (!card) {
          return;
        }

        const user = card.user;
        this.handleUser(user);
      });
    });
  }

  ngOnDestroy() {
    this.scanObserver.unsubscribe();
  }

  getMeals() {
    this.mealsService.get().subscribe(meals => (this.meals = meals));
  }

  print() {
    this.mealsService.print(this.selectedMeal._id);
  }

  selectMeal(meal) {
    this.selectedMeal = meal;
    this.mealsService.getById(meal._id).subscribe(e => (this.selectedMeal = e));
  }

  handleUser(user) {
    this.lastUser = user;

    if (this.hadMeal(user)) {
      this.translateService
        .get('MEALS.ALREADY_HAD_MEAL', {
          fullName: user.fullName
        })
        .subscribe(message => {
          this.alertService.showWarning(message);
        });
      return;
    }

    let userFound = false;

    for (let i1 = 0; i1 < this.selectedMeal.groups.length; i1++) {
      const group = this.selectedMeal.groups[i1];
      for (let i2 = 0; i2 < group.users.length; i2++) {
        const e = group.users[i2];
        if (String(e._id) === String(user._id)) {
          userFound = true;
          break;
        }
      }

      if (userFound) {
        break;
      }
    }

    if (!userFound) {
      this.translateService
        .get('MEALS.PERSON_NOT_IN_PRESENCE', {
          fullName: user.fullName
        })

        .subscribe(message => {
          this.alertService.showWarning(message);
        });
      return;
    }

    this.selectedMeal.users.push(user);
    this.mealsService.update(this.selectedMeal).subscribe();
    this.alertService.showSuccess('MEALS.REGISTERED');

    if (user.foodInfo) {
      this.translateService
        .get('MEALS.SPECIAL_FOOD_INFO', {
          fullName: user.fullName,
          foodInfo: user.foodInfo
        })

        .subscribe(message => {
          this.alertService.showInfo(message, 10 * 1000);
        });
    }
  }

  getRemaining() {
    if (!this.selectedMeal) {
      return 0;
    }
    return this.selectedMeal.total - this.selectedMeal.users.length;
  }

  hadMeal(user) {
    if (!this.selectedMeal) {
      return false;
    }

    for (let index = 0; index < this.selectedMeal.users.length; index++) {
      const e = this.selectedMeal.users[index];
      if (String(e._id) === String(user._id)) {
        return true;
      }
    }

    return false;
  }

  toggleUser(user) {
    if (this.hadMeal(user)) {
      for (let index = 0; index < this.selectedMeal.users.length; index++) {
        const e = this.selectedMeal.users[index];

        if (String(e._id) === String(user._id)) {
          this.selectedMeal.users.splice(index, 1);
        }
      }
    } else {
      this.handleUser(user);
    }

    this.mealsService.update(this.selectedMeal).subscribe();
  }
}
