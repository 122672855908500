import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ProductService } from '@app/services/product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { AuthService } from '@app/services/auth.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {
  @ViewChild(ContextMenuComponent, { static: false }) public basicMenu: ContextMenuComponent;
  public searchTime: any;
  public skip;
  public limit;
  public products;
  public query = '';
  public env = environment;
  public sub: Subscription;

  constructor(
    public productService: ProductService,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.refresh();
    });

    $('input[type=text]').on('keydown', function(e) {
      if (e.which === 13) {
        $(this).select();
      }
    });
  }

  ngOnDestroy() {
    this.refresh();
  }

  refresh() {
    this.reset();
    this.fetchNext();
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    const height = 306;
    const columns = 4;

    this.limit = Math.ceil((window.innerHeight / height) * columns);
    this.products = [];
    this.skip = 0;
  }

  print() {
    this.productService.print();
  }

  fetchNext() {
    this.sub = this.productService
      .get({
        search: this.query,
        skip: this.skip,
        limit: this.limit
      })
      .subscribe(products => (this.products = this.products.concat(products)));

    this.skip += this.limit;
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.fetchNext();
    }, 300);
  }

  create() {
    this.router.navigate(['products', 'create_product']);
  }

  edit(product) {
    this.router.navigate(['products', 'edit_product', product._id]);
  }

  delete(product) {
    this.productService.delete(product).subscribe(() => {
      for (let i = 0; i < this.products.length; i++) {
        const e = this.products[i];
        if (product._id === e._id) {
          this.products.splice(i, 1);
          return;
        }
      }
    });
  }
}
