import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/services/alert.service';
import { API } from '@app/services/api.service';
import { LoadingService } from '@app/services/loading.service';
import { UserIdleService } from 'angular-user-idle';
declare var $: any;

@Injectable()
export class AuthService {
  constructor(
    public api: API,
    public router: Router,
    public alertService: AlertService,
    public userIdle: UserIdleService,
    public loadingService: LoadingService
  ) {}

  login(username: String, password: String, remember_me: Boolean, user?: any) {
    const body: any = {
      username: username,
      password: password,
      remember_me: remember_me
    };

    if (user) {
      body.user = user;
    }

    const query = this.api.post('auth/login', body);

    query.subscribe(res => this.completeLogin(res));

    return query;
  }

  loginWithScan(scan_code: String, remember_me) {
    const query = this.api.post('auth/login', {
      scan_code: scan_code,
      remember_me: remember_me
    });

    query.subscribe(res => this.completeLogin(res));

    return query;
  }

  completeLogin(res) {
    this.alertService.removeAlerts();

    res.user.remember_me = res.remember_me;

    localStorage.setItem('token', res.token);
    localStorage.setItem('session', JSON.stringify(res.session));
    localStorage.setItem('user', JSON.stringify(res.user));
    localStorage.setItem('permissions', JSON.stringify(res.permissions));
    localStorage.setItem('language', res.user.language);

    if (res.user.night_mode_setting !== 'auto') {
      localStorage.setItem('night_mode_setting', res.user.night_mode_setting);
    }

    console.log('token: ' + localStorage.getItem('token'));
    console.log('session: ' + localStorage.getItem('session'));

    if (res.remember_me) {
      this.userIdle.stopWatching();
    } else {
      this.userIdle.startWatching();
    }

    this.router.navigate(['/home']);

    // setTimeout(() => {
    //   if ($('.navbar-button').length > 2) {
    //     $('.navbar-button:nth-child(2)').click();
    //   }
    // }, 50);

    if (localStorage.getItem('default_page') !== null) {
      this.router.navigate(JSON.parse(localStorage.getItem('default_page')));
    }
  }

  logout(): any {
    localStorage.removeItem('session');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('permissions');
    localStorage.removeItem('night_mode_setting');
    localStorage.removeItem('language');

    this.alertService.removeAlerts();
    this.loadingService.hideLoader();

    this.userIdle.stopWatching();
    this.router.navigate(['/login']);
  }

  getPermissions() {
    return JSON.parse(localStorage.getItem('permissions'));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  hasPermission(perm_check) {
    const permissions = this.getPermissions();

    if (!permissions) {
      return false;
    }

    perm_check = perm_check.replace('*', '.*');

    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i];
      if (permission.match(perm_check)) {
        return true;
      }
    }

    return false;
  }

  impersonate(user) {
    this.login(user.email, '', true, this.getUser());
  }
}
