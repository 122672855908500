import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ProductService } from '@app/services/product.service';
declare var $: any;

@Component({
  selector: 'app-products-edit',
  templateUrl: './products-edit.component.html',
  styleUrls: ['./products-edit.component.scss']
})
export class ProductsEditComponent implements OnInit, OnDestroy {
  public DEFAULT_IMAGE = '/assets/images/default_product.png';
  public product;
  public id;
  public env = environment;
  public validation = {
    image: true,
    name: true,
    salesPrice: true,
    netCosts: true
  };

  constructor(public route: ActivatedRoute, public productService: ProductService, public location: Location, public router: Router) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getProduct();
    });
  }

  ngOnDestroy() {
    this.getProduct();
  }

  getProduct() {
    this.productService.getById(this.id).subscribe(product => {
      this.product = product;

      $('.modal .image-container').css('background-image', 'url(' + environment.IP_ADDRESS + '/api/upload/' + this.product.image + '.png)');
    });
  }

  showImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (event2: any) => {
        this.product.image = event2.target.result;
        $('.modal .image-container').css('background-image', 'url(' + this.product.image + ')');
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  save() {
    this.validation.image = this.product.image !== this.DEFAULT_IMAGE;
    this.validation.name = !!this.product.name;
    this.validation.salesPrice = !!this.product.salesPrice;
    this.validation.netCosts = !!this.product.netCosts;

    if (this.validation.image && this.validation.name && this.validation.salesPrice && this.validation.netCosts) {
      this.productService.update(this.product).subscribe(() => this.router.navigate(['products']));
    }
  }

  addTopping(topping) {
    for (let i = 0; i < this.product.toppings.length; i++) {
      if (this.product.toppings[i]._id === topping._id) {
        return;
      }
    }

    this.product.toppings.push(topping);
  }

  removeTopping(topping) {
    for (let i = 0; i < this.product.toppings.length; i++) {
      if (this.product.toppings[i]._id === topping._id) {
        this.product.toppings.splice(i, 1);
        return;
      }
    }
  }
}
