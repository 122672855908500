import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
declare var $: any;

@Injectable()
export class ScanService {
  public handleFocus: Boolean;
  public obs = new Subject<string>();
  public obs$ = this.obs.asObservable();
  public scan_time: any;
  public scan_code = '';

  constructor() {
    window.addEventListener('keypress', event => {
      this.handleScan(event);
    });
  }

  getObserver() {
    return this.obs$;
  }

  setHandleFocus(handleFocus) {
    this.handleFocus = handleFocus;
  }

  handleScan(event: KeyboardEvent) {
    if ((event.charCode < 48 || event.charCode > 57) && event.charCode !== 13) {
      return;
    }

    if (this.handleFocus && $('input[type=text], .nav-item, button').is(':focus')) {
      $('input[type=text], .nav-item, button').blur();
    }

    this.scan_time = new Date();
    setTimeout(() => {
      const now: any = new Date();

      if (event.key !== 'Enter') {
        this.scan_code += event.key;
        return;
      }

      if (this.scan_code.length === 0) {
        return;
      }

      console.log('Scan service reports: ' + this.scan_code);
      this.obs.next(this.scan_code);
      this.scan_code = '';
    }, 20);
  }
}
