import { Injectable } from '@angular/core';
import { AlertService } from '@app/services/alert.service';
import { API } from '@app/services/api.service';
import { BaseService } from '@app/services/base.service';

@Injectable()
export class CategoryService extends BaseService {
  constructor(public api: API, public alertService: AlertService) {
    super(api, alertService);
    this.route = 'categories';
  }
}
