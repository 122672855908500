import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AlertService } from '@app/services/alert.service';
import { SettingsService } from '@app/services/settings.service';
import { BaseService } from '@app/services/base.service';
import { API } from '@app/services/api.service';
import { CardService } from '@app/services/card.service';
import { OrderService } from '@app/services/order.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Injectable()
export class SalesService extends BaseService {
  public obs = new Subject<any>();
  public obs$ = this.obs.asObservable();
  public order;
  public snackBarOpen = false;
  public settings_updated_at;

  constructor(
    public api: API,
    public http: HttpClient,
    public alertService: AlertService,
    public router: Router,
    public settingsService: SettingsService,
    public cardService: CardService,
    public orderService: OrderService,
    public translateService: TranslateService
  ) {
    super(api, alertService);
    this.route = 'sales';
    this.reset();
  }

  getObserver() {
    return this.obs$;
  }

  handleCardScan(scan_code) {
    const query = this.cardService.getByScanCode(scan_code).toPromise();

    query.then(card => {
      this.order.user = card.user;
    });

    return query;
  }

  isSnackbarOpen() {
    const now: any = new Date();
    if (now - this.settings_updated_at < 10 * 60 * 1000) {
      return this.snackBarOpen;
    }

    this.getStatus();
  }

  reset() {
    this.order = {
      orderLines: []
    };

    this.getStatus();
  }

  getStatus() {
    this.settings_updated_at = new Date();

    this.settingsService.get().subscribe(settings => {
      this.snackBarOpen = settings.snackBarOpen;
      return this.snackBarOpen;
    });
  }

  chooseToppings(product) {
    const outlets: any = {};
    let url;
    outlets.modal = `choose_toppings/${product._id}`;

    if (this.router.url.startsWith('/bar')) {
      url = '/bar';
    } else if (this.router.url.startsWith('/self-service')) {
      url = '/self-service';
      outlets.self_service = 'order';
    }

    this.router.navigate([url, { outlets: outlets }]);
    $('#modal').modal('show');
  }

  addProduct(product, toppings?) {
    if (this.isInactive(product)) {
      this.alertService.showWarning('SALES.INACTIVE_PRODUCT');
      return;
    }

    if (product.toppings.length > 0 && toppings === undefined) {
      return this.chooseToppings(product);
    }

    if (!toppings) {
      toppings = [];
    }

    const topping_ids = [];
    toppings.forEach(topping => topping_ids.push(topping._id));

    for (let i = 0; i < this.order.orderLines.length; i++) {
      const orderLine = this.order.orderLines[i];

      if (product._id === orderLine.product._id) {
        if (toppings.length === 0 && orderLine.toppings.length === 0) {
          return (orderLine.quantity += 1);
        }

        const orderLine_topping_ids = [];
        orderLine.toppings.forEach(topping => orderLine_topping_ids.push(topping._id));

        let match = true;

        if (topping_ids.length !== orderLine_topping_ids.length) {
          match = false;
        }

        topping_ids.forEach(e1 => {
          if (!orderLine_topping_ids.includes(e1)) {
            match = false;
          }
        });

        if (match) {
          return (orderLine.quantity += 1);
        }
      }
    }

    this.order.orderLines.push({
      product: product,
      toppings: toppings,
      quantity: 1
    });
  }

  removeProduct(product, toppings?) {
    const topping_ids = [];
    toppings.forEach(topping => topping_ids.push(topping._id));

    for (let i = 0; i < this.order.orderLines.length; i++) {
      const orderLine = this.order.orderLines[i];

      if (product._id === orderLine.product._id) {
        if (toppings.length === 0 && orderLine.toppings.length === 0) {
          return this.order.orderLines.splice(i, 1);
        }

        const orderLine_topping_ids = [];
        orderLine.toppings.forEach(topping => orderLine_topping_ids.push(topping._id));

        let match = true;

        topping_ids.forEach(e1 => {
          if (!orderLine_topping_ids.includes(e1)) {
            match = false;
          }
        });

        if (match) {
          return this.order.orderLines.splice(i, 1);
        }
      }
    }

    this.order.orderLines.push({
      product: product,
      toppings: toppings,
      quantity: 1
    });
  }

  isUnderage(product) {
    if (!this.order.user) {
      return false;
    }
    const user = this.order.user;
    const age = moment().diff(moment(user.dob), 'years');

    return product.minAge > age;
  }

  isInactive(product) {
    return this.isUnderage(product) || this.getStock(product) < 1 || (product.isSnack && !this.isSnackbarOpen());
  }

  getStock(product) {
    let stock = product.stock;

    for (let index = 0; index < this.order.orderLines.length; index++) {
      const orderLine = this.order.orderLines[index];

      if (orderLine.product._id === product._id) {
        stock -= orderLine.quantity;
      }

      orderLine.toppings.forEach(topping => {
        if (topping._id === product._id) {
          stock -= orderLine.quantity;
        }
      });
    }

    return stock;
  }

  useNetCosts() {
    if (!this.order.user) {
      return false;
    }

    if (this.order.user.balance.compensation === 0) {
      return false;
    }

    if (this.order.user.balance.compensation >= this.getTotalPrice(true)) {
      return true;
    }

    return false;
  }

  getTotalPrice(compensation = this.useNetCosts()) {
    let total = 0;

    if (compensation) {
      this.order.orderLines.forEach(orderLine => {
        total += orderLine.product.netCosts * orderLine.quantity;

        orderLine.toppings.forEach(topping => {
          total += topping.netCosts * orderLine.quantity;
        });
      });
    } else {
      this.order.orderLines.forEach(orderLine => {
        total += orderLine.product.salesPrice * orderLine.quantity;

        orderLine.toppings.forEach(topping => {
          total += topping.salesPrice * orderLine.quantity;
        });
      });
    }

    return total;
  }

  getTotalPriceOfOrderLine(orderLine, compensation = this.useNetCosts()) {
    let total = 0;

    if (compensation) {
      total += orderLine.product.netCosts * orderLine.quantity;

      orderLine.toppings.forEach(topping => {
        total += topping.netCosts * orderLine.quantity;
      });
    } else {
      total += orderLine.product.salesPrice * orderLine.quantity;

      orderLine.toppings.forEach(topping => {
        total += topping.salesPrice * orderLine.quantity;
      });
    }

    return total;
  }

  isValidOrder() {
    if (!this.order.user) {
      return false;
    }
    if (this.order.orderLines.length < 1) {
      return false;
    }

    for (let index = 0; index < this.order.orderLines.length; index++) {
      const orderLine = this.order.orderLines[index];
      if (this.isUnderage(orderLine.product) || this.getStock(orderLine.product) < 0) {
        return false;
      }
    }

    return true;
  }

  increaseQuantity(orderLine) {
    orderLine.quantity++;
  }

  lowerQuantity(orderLine) {
    if (orderLine.quantity > 1) {
      return (orderLine.quantity -= 1);
    }

    this.removeProduct(orderLine.product, orderLine.toppings);
  }

  printOrder(order = this.order) {
    return this.http.post('http://10.0.0.14:3001/escpos/order', order);
    // return this.http.post('http://localhost:3001/escpos/order', order);
  }

  confirmOrder() {
    this.order.total = this.getTotalPrice();
    this.order.type = 'sales';

    const query = this.orderService.create(this.order).toPromise();

    query.then(order => {
      this.alertService.showSuccess('SALES.ORDER_COMPLETED');
      if (order.order_number) {
        this.translateService
          .get('SALES.ORDER_COMPLETED_ORDER_NUMBER', { order_number: order.order_number })
          .toPromise()
          .then(res => this.alertService.showInfo(res));
      }

      if (order.completed) {
        return this.reset();
      }

      this.printOrder(order).subscribe(() => {
        this.reset();
      });
    });

    return query;
  }
}
