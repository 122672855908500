import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timePast',
  pure: false
})
export class TimePastPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const now = moment()
      .utc()
      .toDate();

    let prefix = '';
    const time_past = moment.utc(moment(now).diff(moment(value)));
    let format = 'mm:ss';

    if (time_past.hours() > 0) {
      format = 'HH:mm:ss';
    }

    const days_past = moment(now).diff(moment(value), 'days');

    if (days_past > 0) {
      prefix = days_past + ' dag(en) ';
    }

    return prefix + time_past.format(format);
  }
}
