import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { CardService } from '@app/services/card.service';
import { UserService } from '@app/services/user.service';
declare var $: any;

@Component({
  selector: 'app-cards-edit',
  templateUrl: './cards-edit.component.html',
  styleUrls: ['./cards-edit.component.scss']
})
export class CardsEditComponent implements OnInit, OnDestroy {
  public env = environment;
  public id;
  public card;
  public validation = {
    user: true
  };

  constructor(public cardService: CardService, public userService: UserService, public route: ActivatedRoute, public router: Router) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getCard();
    });
  }

  ngOnDestroy() {
    this.getCard();
  }

  getCard(): any {
    this.cardService.getById(this.id).subscribe(card => (this.card = card));
  }

  save() {
    this.validation.user = !!this.card.user;

    if (this.validation.user) {
      this.cardService.update(this.card).subscribe(() => this.router.navigate(['cards']));
    }
  }
}
