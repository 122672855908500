export let ALL_PERMISSIONS = [
  'bar_sales',
  'bar_snackbar',
  'broadcasts:create',
  'broadcasts:delete',
  'cards:create',
  'cards:delete',
  'cards:read',
  'cards:update',
  'categories:create',
  'categories:delete',
  'categories:read',
  'categories:update',
  'finance_mutations',
  'finance_transactions',
  'first_aid',
  'groups:create',
  'groups:delete',
  'groups:read',
  'groups:update',
  'impersonate',
  'kitchen_meals',
  'kitchen_orders:create',
  'kitchen_orders:read',
  'kitchen_export_orders:create',
  'kitchen_export_orders:read',
  'permissions:read',
  'permissions:update',
  'presences:read',
  'presences:update',
  'products:create',
  'products:delete',
  'products:read',
  'products:update',
  'security_checkinout',
  'settings',
  'users:create',
  'users:delete',
  'users:read',
  'users:update'
];
