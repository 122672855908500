import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { AlertService } from '@app/services/alert.service';
import { API } from '@app/services/api.service';

@Injectable()
export class SettingsService extends BaseService {
  constructor(public api: API, public alertService: AlertService) {
    super(api, alertService);
    this.route = 'settings';
  }

  save(settings) {
    return this.api.post(this.route, { object: settings });
  }
}
