import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ProductService } from '@app/services/product.service';
import { CategoryService } from '@app/services/category.service';
declare var $: any;

@Component({
  selector: 'app-categories-edit',
  templateUrl: './categories-edit.component.html',
  styleUrls: ['./categories-edit.component.scss']
})
export class CategoriesEditComponent implements OnInit, OnDestroy {
  public category;
  public env = environment;
  public id;
  public validation = {
    name: true
  };

  constructor(
    public route: ActivatedRoute,
    public categoryService: CategoryService,
    public location: Location,
    public router: Router,
    public productService: ProductService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getCategory();
    });
  }

  ngOnDestroy() {
    this.getCategory();
  }

  getCategory() {
    this.categoryService.getById(this.id).subscribe(category => {
      this.category = category;
    });
  }

  save() {
    this.validation.name = !!this.category.name;

    if (this.validation.name) {
      this.categoryService.update(this.category).subscribe(() => this.router.navigate(['categories']));
    }
  }

  addProduct(product) {
    this.category.products.push(product);
  }

  removeProduct(product) {
    for (let i = 0; i < this.category.products.length; i++) {
      if (this.category.products[i]._id === product._id) {
        this.category.products.splice(i, 1);
        return;
      }
    }
  }
}
