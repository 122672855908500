import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
declare var $: any;

@Injectable()
export class DeleteService {
  public item;
  public event;

  constructor() {}

  delete(item, event) {
    this.item = item;
    this.event = event;
    $('#delete-modal').modal('show');
  }

  confirm() {
    this.event.emit(this.item);
    $('#delete-modal').modal('hide');
  }
}
