import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit, OnDestroy {
  @Input()
  service: BaseService;
  @Input()
  exclude = [];
  @Output()
  selected = new EventEmitter();

  public searchTime: any;
  public query = '';
  public items;
  public env = environment;
  public sub: Subscription;

  constructor() {}

  ngOnInit() {
    this.reset();

    $('#search').on('focus', () => {
      this.fetchNext();
      $(this).select();
    });

    $('#search').on('keydown', e => {
      if (e.keyCode === 13) {
        if (this.items.length > 0) {
          this.addItem(this.items[0]);
        }
      }
    });

    $(document).on('mouseup', e => {
      const container = $('.search-block');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        this.reset();
      }
    });

    this.selected.subscribe(() => this.reset());
  }

  ngOnDestroy() {
    $('#search').unbind('focus');
    $('#search').unbind('keydown');
    $(document).unbind('mouseup');
    this.selected.unsubscribe();
    this.reset();
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.items = [];
  }

  addItem(item) {
    this.selected.emit(item);
  }

  getImage(item) {
    return item.profileImage || item.image || '/assets/images/default_product.png';
  }

  getName(item) {
    return item.fullName || item.name || 'NOT_AVAILABLE';
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      this.fetchNext();
    }, 300);
  }

  fetchNext() {
    this.sub = this.service.get({ search: this.query, limit: 10, exclude: this.getExclude() }).subscribe(items => {
      this.items = items;
      $('.search-results').scrollTop(0);
    });
  }

  getExclude() {
    const exclude_ids = [];

    for (let i = 0; i < this.exclude.length; i++) {
      const item = this.exclude[i];
      exclude_ids.push(item._id);
    }

    return exclude_ids;
  }
}
