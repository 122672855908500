import { Injectable } from '@angular/core';
import { AlertService } from '@app/services/alert.service';
import { API } from '@app/services/api.service';
import { BaseService } from '@app/services/base.service';
import * as moment from 'moment';

@Injectable()
export class TransactionService extends BaseService {
  constructor(api: API, alertService: AlertService) {
    super(api, alertService);
    this.route = 'transactions';
  }

  getByUser(id) {
    return this.api.get(this.route + '/user/' + id);
  }

  export(query) {
    // window.open(this.api.url + this.route + '/' + id + '/print?token=' + localStorage.getItem('token'));

    let csv = 'name;groups;amount;new_balance;date;transaction_type;balance_type;issued_by;\n';
    this.get(query).subscribe(transactions => {
      for (let i = 0; i < transactions.length; i++) {
        const transaction = transactions[i];
        if (transaction) {
          const name = transaction.user ? transaction.user.fullName : '';
          const groups = transaction.user ? transaction.groups : '';

          csv += `${name};${groups};${transaction.amount
            .toFixed(2)
            .toString()
            .replace('.', ',')};${transaction.new_balance
            .toFixed(2)
            .toString()
            .replace('.', ',')};${moment(transaction.date).format('DD-MM-YYYY HH:mm')};${transaction.transaction_type};${
            transaction.balance_type
          };${transaction.issued_by.fullName};\n`;
        }
      }

      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
