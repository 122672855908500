import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { OrderService } from '@app/services/order.service';
import { ScanService } from '@app/services/scan.service';
import { AlertService } from '@app/services/alert.service';
import io from 'socket.io-client';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit, OnDestroy {
  private socket;
  public orders = [];
  public env = environment;
  public scanObserver;

  constructor(public orderService: OrderService, public scanService: ScanService, public alertService: AlertService) {}

  ngOnInit() {
    this.initSocket();

    this.onOrder().subscribe(e => {
      this.orders.push(e);
    });

    this.scanService.setHandleFocus(true);
    this.scanObserver = this.scanService.getObserver().subscribe(scan_code => {
      this.handleScan(scan_code);
    });
  }

  ngOnDestroy() {
    this.scanService.setHandleFocus(false);
    this.scanObserver.unsubscribe();
  }

  getOrders() {
    this.orderService.getSnackbarOrders().subscribe(orders => (this.orders = orders));
  }

  handleScan(scan_code) {
    this.orderService.get({ query: { barcode: scan_code } }).subscribe(orders => {
      if (orders.length !== 1) {
        return this.alertService.showError('UNKNOWN_SCANCODE');
      }

      this.complete(orders[0]);
    });
  }

  initSocket(): void {
    this.socket = io(environment.IP_ADDRESS.replace(':4200', ':3000'), {
      path: '/api/snackbar',
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      }
    });

    this.socket.on('disconnect', () => (this.orders = []));
  }

  complete(order) {
    this.socket.emit('order_complete', order);
    this.orders.splice(this.orders.indexOf(order), 1);
  }

  onOrder(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('order_new', data => observer.next(data));
    });
  }
}
