import { Component, OnInit } from '@angular/core';
import { SalesService } from '@app/services/sales.service';
import { ProductService } from '@app/services/product.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ss-order',
  templateUrl: './ss-order.component.html',
  styleUrls: ['./ss-order.component.scss']
})
export class SsOrderComponent implements OnInit {
  public products = [];
  public env = environment;

  constructor(public salesService: SalesService, public productService: ProductService, public router: Router) {}

  ngOnInit() {
    this.getProducts();
  }

  getProducts() {
    this.productService.get({ query: { isSnack: true } }).subscribe(products => (this.products = products));
  }

  cancel() {
    this.router.navigate(['/self-service', { outlets: { self_service: 'start' } }]);
  }

  confirm() {
    this.router.navigate(['/self-service', { outlets: { self_service: 'confirm' } }]);
  }
}
