import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from '@app/services/auth.service';
import { NightModeService } from '@app/services/night-mode.service';
import { AppService } from '@app/services/app.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public user;
  public env = environment;

  constructor(
    public router: Router,
    public authService: AuthService,
    public nightModeService: NightModeService,
    public appService: AppService
  ) {}

  ngOnInit() {}

  getProfileImage() {
    if (!localStorage.getItem('user')) {
      return '/assets/images/default_profile.jpg';
    }

    return this.env.IP_ADDRESS + '/api/upload/' + JSON.parse(localStorage.getItem('user')).profileImage;
  }
}
