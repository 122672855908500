import { Injectable } from '@angular/core';
import { AlertService } from '@app/services/alert.service';
import { API } from '@app/services/api.service';
import { BaseService } from '@app/services/base.service';

@Injectable()
export class OrderService extends BaseService {
  constructor(public api: API, public alertService: AlertService) {
    super(api, alertService);
    this.route = 'orders';
  }

  getSnackbarOrders() {
    return this.api.get(
      this.route + '/snackbar',
      {},
      {},
      {
        disableLoader: true
      }
    );
  }

  completeOrder(order) {
    order.completed = true;
    return this.update(order);
  }
}
