import { Injectable } from '@angular/core';

@Injectable()
export class LoadingService {
  public active: boolean;
  public queries = [];

  constructor() {}

  showLoader(query?) {
    if (query) {
      this.queries.push(query);
    }

    setTimeout(() => {
      this.active = true;
    });
  }

  hideLoader(query?) {
    if (query) {
      for (let i = 0; i < this.queries.length; i++) {
        const e = this.queries[i];
        if (query === e) {
          this.queries.splice(i, 1);
          break;
        }
      }
    }

    if (this.queries.length > 0) {
      return;
    }

    setTimeout(() => {
      this.active = false;
    });
  }
}
