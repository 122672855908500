import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AlertService } from '@app/services/alert.service';
import { ProductService } from '@app/services/product.service';
import { SalesService } from '@app/services/sales.service';
declare var $: any;

@Component({
  selector: 'app-choose-toppings',
  templateUrl: './choose-toppings.component.html',
  styleUrls: ['./choose-toppings.component.scss']
})
export class ChooseToppingsComponent implements OnInit, OnDestroy {
  public env = environment;
  public product;
  public selectedToppings;
  public prodId;

  constructor(
    public route: ActivatedRoute,
    public productService: ProductService,
    public salesService: SalesService,
    public alertService: AlertService,
    public router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.prodId = params['prodId'];
      this.getProduct();
    });

    $('#modal').on('hide.bs.modal', () => {
      this.getProduct();
      $('#modal').unbind('keydown');
      $('#modal').unbind('hide.bs.modal');
    });

    $('#modal').on('show.bs.modal', () => {
      this.selectedToppings = [];

      $('#modal').on('keydown', e => {
        if (e.keyCode === 13) {
          $('.btn-primary').click();
        }
      });
    });
  }

  ngOnDestroy() {
    $('#modal').unbind('show.bs.modal');
  }

  getProduct() {
    this.selectedToppings = [];
    this.productService.getById(this.prodId).subscribe(product => (this.product = product));
  }

  toggleTopping(topping) {
    if (this.salesService.isInactive(topping)) {
      this.alertService.showWarning('SALES.INACTIVE_PRODUCT');
      return;
    }

    for (let i = 0; i < this.selectedToppings.length; i++) {
      const selectedTopping = this.selectedToppings[i];
      if (String(selectedTopping._id) === String(topping._id)) {
        this.selectedToppings.splice(i, 1);
        return;
      }
    }

    this.selectedToppings.push(topping);
  }

  isSelected(topping) {
    for (let i = 0; i < this.selectedToppings.length; i++) {
      const selectedTopping = this.selectedToppings[i];
      if (String(selectedTopping._id) === String(topping._id)) {
        return true;
      }
    }

    return false;
  }

  confirmToppings() {
    this.salesService.addProduct(this.product, this.selectedToppings);
    $('#modal').modal('hide');
  }
}
