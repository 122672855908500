import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AlertService } from '@app/services/alert.service';
import { ProductService } from '@app/services/product.service';
import { ScanService } from '@app/services/scan.service';
import { Subscription } from 'rxjs';
import { OrderService } from '@app/services/order.service';
declare var $: any;

@Component({
  selector: 'app-goods-export-create',
  templateUrl: './goods-export-create.component.html',
  styleUrls: ['./goods-export-create.component.scss']
})
export class GoodsExportCreateComponent implements OnInit, OnDestroy {
  public scanObserver: Subscription;
  public searchTime: any;
  public skip;
  public limit;
  public products;
  public order;
  public query = '';
  public env = environment;
  public sub: Subscription;

  constructor(
    public productService: ProductService,
    public orderService: OrderService,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public scanService: ScanService,
    public alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.query = params['q'];
      }

      this.refresh();
    });

    this.scanService.setHandleFocus(true);
    this.scanObserver = this.scanService.getObserver().subscribe(scan_code => {
      this.productService.getByScanCode(scan_code).subscribe(product => {
        product.quantity = 1;
        this.addProduct(product);
      });
    });

    this.refresh();

    $('input[type=text]').on('keydown', function(e) {
      if (e.which === 13) {
        $(this).select();
      }
    });

    this.clearOrder();
  }

  clearOrder() {
    this.order = {
      orderLines: [],
      type: 'goods_export'
    };
  }

  ngOnDestroy() {
    this.scanService.setHandleFocus(false);
    this.scanObserver.unsubscribe();
    this.refresh();
    this.clearOrder();
  }

  refresh() {
    this.reset();
    this.fetchNext();
  }

  reset() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    const height = 132;
    const columns = 2;

    this.limit = Math.ceil((window.innerHeight / height) * columns);
    this.products = [];
    this.skip = 0;
  }

  fetchNext() {
    this.sub = this.productService
      .get({
        search: this.query,
        skip: this.skip,
        limit: this.limit
      })
      .subscribe(products => {
        products.forEach(product => {
          product.quantity = 1;
        });

        this.products = this.products.concat(products);
      });

    this.skip += this.limit;
  }

  search() {
    this.searchTime = new Date();
    setTimeout(() => {
      const now: any = new Date();
      if (now - this.searchTime < 300) {
        return;
      }

      if (this.query) {
        this.location.replaceState(this.router.url.split('?')[0] + '?q=' + this.query);
      } else {
        this.location.replaceState(this.router.url.split('?')[0] + '');
      }

      this.reset();
      this.fetchNext();
    }, 300);
  }

  save() {
    if (this.order.orderLines.length === 0) {
      return this.alertService.showWarning('GOODS-EXPORT.NO_ORDER_LINES');
    }

    this.orderService.create(this.order).subscribe(() => {
      this.order.orderLines = [];
    });
  }

  addProduct(product) {
    for (let i = 0; i < this.order.orderLines.length; i++) {
      const orderLine = this.order.orderLines[i];

      if (product._id === orderLine.product._id) {
        orderLine.quantity += product.quantity;
        return;
      }
    }

    this.order.orderLines.push({
      product: product,
      quantity: product.quantity
    });
  }

  deleteOrderLine(orderLine) {
    for (let i = 0; i < this.order.orderLines.length; i++) {
      const e = this.order.orderLines[i];

      if (orderLine.product._id === e.product._id) {
        this.order.orderLines.splice(i, 1);
        return;
      }
    }
  }
}
